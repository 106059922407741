import React from 'react'
import styled from 'styled-components'
import EmailCapture from '../EmailCapture'

const Wrapper = styled.div`
  background-color: ${props => props.color};
  color: white;
  text-align: center;
  padding: ${mobileVW(41)} ${mobileVW(28)} ${mobileVW(48)};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
    padding: 0;
    text-align: left;
  }

  .homepage_newsletter {
    color: ${colors.brownDark};
    margin-top: ${mobileVW(35)};
    position: relative;
    width: 100%;
    background: ${colors.beigeUltraLight};
    border-radius: ${mobileVW(100)};
    padding: ${mobileVW(10)} 0 ${mobileVW(10)} ${mobileVW(21)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 32px;
      padding: 11px 0 10px 21px;
      font-size: 18px;
    }

    input {
      font-size: ${mobileVW(16)};
      line-height: ${mobileVW(19)};
      width: 90%;
      border: none;
      background: inherit;
      color: ${colors.brownDark};

      &::placeholder {
        color: ${colors.brownDark};
        text-transform: lowercase;
      }

      &:focus {
        outline: none;
      }
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    button {
      font-size: ${mobileVW(16)};
      line-height: ${mobileVW(19)};
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      border-radius: ${mobileVW(100)};
      border: solid ${mobileVW(2)} ${colors.beigeUltraLight};
      padding: 0 ${mobileVW(24)};
      font-family: ${font.ben};
      color: ${colors.beigeUltraLight};
      background: ${colors.brownLight} !important;
      text-transform: lowercase;
      font-weight: normal;
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 16px;
        line-height: 19px;
        border-radius: 100px;
        border: solid 2px ${colors.beigeUltraLight};
        padding: 0 24px;
        right: -5px;
      }
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(48)};
  line-height: ${mobileVW(52)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
  }
`

const Subtitle = styled.h3`
  font-family: ${font.larsseit};
  margin-top: ${mobileVW(15)};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
  }
`

import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

const NewsletterBlock = ({ content }) => {
  const { title, subtitle, backgroundColor } = content

  return (
    <>
      <Wrapper
        className='homepage_newsletter_wrapper'
        color={backgroundColor || colors.orange}
      >
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <EmailCapture className='homepage_newsletter' buttonText='send' />
      </Wrapper>
    </>
  )
}

export default NewsletterBlock
