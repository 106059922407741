import React from 'react'

const Facebook = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.004 26.004'>
    <g transform='translate(-94.117 -353.751)'>
      <path
        d='M107.119,356.094c3.472,0,3.883.013,5.254.076a7.179,7.179,0,0,1,2.414.447,4.31,4.31,0,0,1,2.468,2.468,7.193,7.193,0,0,1,.448,2.414c.062,1.371.075,1.782.075,5.254s-.013,3.883-.075,5.254a7.193,7.193,0,0,1-.448,2.414,4.3,4.3,0,0,1-2.468,2.468,7.2,7.2,0,0,1-2.414.448c-1.371.062-1.782.076-5.254.076s-3.883-.014-5.254-.076a7.194,7.194,0,0,1-2.414-.448,4.3,4.3,0,0,1-2.468-2.468,7.192,7.192,0,0,1-.448-2.414c-.062-1.371-.075-1.782-.075-5.254s.013-3.883.075-5.254a7.193,7.193,0,0,1,.448-2.414,4.31,4.31,0,0,1,2.468-2.468,7.173,7.173,0,0,1,2.414-.447c1.371-.063,1.782-.076,5.254-.076m0-2.343c-3.531,0-3.974.015-5.361.078a9.521,9.521,0,0,0-3.156.6,6.641,6.641,0,0,0-3.8,3.8,9.527,9.527,0,0,0-.605,3.156c-.063,1.387-.078,1.83-.078,5.361s.015,3.974.078,5.361a9.521,9.521,0,0,0,.605,3.156,6.646,6.646,0,0,0,3.8,3.8,9.549,9.549,0,0,0,3.156.6c1.387.063,1.83.078,5.361.078s3.974-.015,5.361-.078a9.549,9.549,0,0,0,3.156-.6,6.652,6.652,0,0,0,3.8-3.8,9.55,9.55,0,0,0,.6-3.156c.063-1.387.078-1.83.078-5.361s-.015-3.974-.078-5.361a9.555,9.555,0,0,0-.6-3.156,6.646,6.646,0,0,0-3.8-3.8,9.521,9.521,0,0,0-3.156-.6c-1.387-.063-1.83-.078-5.361-.078'
        fill='#e3edff'
      />
      <path
        d='M107.119,360.076a6.677,6.677,0,1,0,6.677,6.677,6.678,6.678,0,0,0-6.677-6.677m0,11.011a4.334,4.334,0,1,1,4.334-4.334,4.333,4.333,0,0,1-4.334,4.334'
        fill='#e3edff'
      />
      <path
        d='M115.62,359.813a1.561,1.561,0,1,1-1.56-1.561,1.561,1.561,0,0,1,1.56,1.561'
        fill='#e3edff'
      />
    </g>
  </svg>
)

export default Facebook
