import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setCartOpen } from '../../actions'
import { font } from '../../styles'

// Hooks
import { useDictionaryCtx, useLayoutCtx, useShopCtx } from '../../hooks/context'

// Components
import Image from 'gatsby-image'
import LineItem from './LineItem'
import CloseIcon from '../graphics/Close'
import AddToCart from '../layout/AddToCart'
import RichText from '../shared/RichText'

const CartWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`

const CartModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;

  opacity: ${({ visible }) => (visible ? 0.5 : 0)};
  transform: translateX(${({ visible }) => (visible ? -100 : 0)}%);
  transition: ${({ visible }) =>
    visible
      ? 'transform 0s linear 0s, opacity .3s ease'
      : 'transform 0s linear .3s, opacity .3s ease'};
`

const CartContainer = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  transition: transform 0.3s ease;
  transform: ${({ visible }) =>
    visible ? 'translateX(-100%)' : 'translateX(0%)'};
`

const CartHeader = styled.header`
  min-height: 80px;
  width: 100%;
  background: #edeae1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CartHeading = styled.h2`
  font-size: 20px;
  line-height: 19px;
  font-family: ${font.ben};
  text-transform: lowercase;
  color: #18100e;
  font-weight: normal;
`

const CartCloseButton = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`

const CartInner = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`

const LineItemsList = styled.ul`
  display: grid;
  grid-auto-rows: 146px;
  list-style: none;
  width: 100%;
  grid-gap: 16px;
  padding: 12px 32px;
  margin-bottom: 32px;
`

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`

const Checkout = styled.div`
  padding: 8px 32px 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.5px solid #9b9b9b;
`

const CheckoutInformation = styled.div`
  font-family: ${font.larsseit};
  font-size: 14px;
  line-height: 21.35px;
  text-align: center;
  width: 60%;
  margin-bottom: 8px;
`

const CheckoutButton = styled.a`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3a679;
  color: white;
  font-family: ${font.ben};
  font-size: 20px;
  border-radius: 100px;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }

  span {
    display: inline-block;
    height: 2px;
    width: 0.65em;
    background: white;
    margin: 0 0.5em;
    margin-top: 0.1em;
  }
`

const Recommendations = styled.section`
  background: #eee3da;
  padding: 40px 0 48px;
`

const RecommendationsInner = styled.div`
  display: flex;
  padding: 0 32px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const RecommendationsHeading = styled.h3`
  font-family: ${font.ben};
  font-size: 20px;
  margin-bottom: 24px;
  padding: 0 32px;
  font-weight: normal;
`

const Recommendation = styled.div`
  min-width: 120px;
  min-height: 226px;
  max-width: 120px;
  padding: 8px;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 104px;
  margin-bottom: 8px;
  border-radius: 3px;
  overflow: hidden;
`

const ProductTitle = styled.h2`
  font-size: 18px;
  font-family: ${font.ben};
  text-transform: lowercase;
  line-height: 19px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.1em;
  padding-bottom: 0.14em;
  word-break: break-word;
  font-weight: normal;
`

const ShortDescription = styled.p`
  font-family: ${font.larsseit};
  font-size: 12px;
  line-height: 13px;
  height: 13px;
  text-transform: uppercase;
  color: #18100e;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.14em;
  word-break: break-word;
  margin-bottom: 4px;
  margin-top: calc(18px * -0.14);
`

const PriceContainer = styled.p`
  color: #9b9b9b;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: calc(12px * -0.14);

  span + span {
    color: #de742c;
    margin-left: 9px;
  }
`

const AddToCartContainer = styled.div`
  margin-top: auto;

  button {
    border: solid 2px #eee3da;
    font-family: ${font.ben};
    font-size: 14px;
    line-height: 15px;
    padding: 10px 0.5em;
    border-radius: 100vw;
    text-transform: lowercase;
    font-weight: normal;

    span {
      margin: 0;

      &.loading {
        font-size: 12px;
      }
    }

    svg {
      display: none;
    }
  }
`

const CartFooter = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 29px 32px;

  dt,
  dd {
    margin: 0;
    padding: 0;
    flex-basis: 50%;
    font-family: ${font.larsseit};
    font-size: 18px;
    margin-bottom: 8px;
  }

  dd {
    text-align: right;
  }

  & > dt:last-of-type,
  & > dd:last-of-type {
    border-top: solid 1px #edeae1;
    font-family: ${font.ben};
    font-size: 20px;
    line-height: 20px;
    padding-top: 24px;
    margin-top: 24px;
    font-weight: normal;
  }
`

const EmptyCart = styled.p`
  padding: 0 32px;
  margin-top: 64px;
  text-align: center;
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  padding: 20px 69px 14px 69px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ProgressBarHeading = styled.h3`
  font-family: ${font.larsseit};
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 6px;
`

const ProgressBarTrack = styled.div`
  height: 11px;
  width: 100%;
  position: relative;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: ${({ color }) => color};
    transition all .3s ease;
  }

  &::before {
    width: 100%;
    opacity: 0.4;
  }

  &::after {
    width: ${({ progress }) => progress}%;
  }
`

// Helpers
function getCartData(lineItems, products) {
  const cartItems = lineItems.edges.map(({ node }) => ({
    ...node,
    product: products.find(p => p.handle === node.variant.product.handle),
  }))

  const amountOfProducts = lineItems.edges.reduce(
    (acc, { node: { quantity } }) => acc + quantity,
    0,
  )

  const subtotal = lineItems.edges.reduce(
    (
      acc,
      {
        node: {
          variant: {
            price: { amount },
          },
          quantity,
        },
      },
    ) => {
      return acc + amount * quantity
    },
    0,
  )

  const recommendations = [
    ...new Set(
      cartItems
        .map(item => item.product.relatedProducts || [])
        .reduce((acc, cur) => acc.concat(cur), []),
    ),
  ].map(handle => products.find(product => product.handle === handle))

  return { cartItems, amountOfProducts, subtotal, recommendations }
}

const ProgressBar = ({ value, goal, color, progressMsg, successMsg }) => {
  const short = Math.floor(goal - value)
  const progress = (value / goal) * 100

  return (
    <ProgressBarWrapper>
      <ProgressBarHeading>
        {short > 0
          ? progressMsg.replace(/\{\s?money\s?\}/, `€${short}`)
          : successMsg}
      </ProgressBarHeading>
      <ProgressBarTrack
        color={color}
        progress={progress > 100 ? 100 : progress}
      />
    </ProgressBarWrapper>
  )
}

const CartRecommendations = React.memo(({ heading, recommendations }) => {
  if (!recommendations.length) return null

  return (
    <Recommendations>
      <RecommendationsHeading>{heading}</RecommendationsHeading>
      <RecommendationsInner>
        {recommendations.map((product, i) => (
          <Recommendation key={`cart-recommendation-${i}`}>
            <ImageContainer>
              <StyledImage
                fluid={product.imageThumbnail.fluid}
                alt={product.title}
              />
            </ImageContainer>

            <ProductTitle title={product.title}>{product.title}</ProductTitle>

            <ShortDescription title={product.productDescriptionShort}>
              {product.productDescriptionShort}
            </ShortDescription>

            <PriceContainer>
              <span>
                €
                {Number(
                  product.shopifyProduct.variants.edges[0].node.price,
                ).toFixed(2)}
              </span>
            </PriceContainer>
            <AddToCartContainer>
              <AddToCart handle={product.shopifyProduct.handle} />
            </AddToCartContainer>
          </Recommendation>
        ))}
      </RecommendationsInner>
    </Recommendations>
  )
})

const Cart = () => {
  const dictionary = useDictionaryCtx()
  const { products } = useShopCtx()
  const cartOpen = useSelector(({ cartOpen }) => cartOpen)
  const dispatch = useDispatch()

  const {
    cartFreeShippingReachedMessage,
    cartFreeShippingUnreachedMessage,
    cartFreeShippingAmount,
    cartShippingCost,
    cartProgressBarColor,
  } = useLayoutCtx()

  const { lineItems, webUrl } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )

  const {
    recommendations,
    subtotal,
    amountOfProducts,
    cartItems,
  } = useMemo(() => getCartData(lineItems, products), [lineItems])

  return (
    <CartWrapper>
      <CartModal
        onClick={() => dispatch(setCartOpen(false))}
        visible={cartOpen}
      />

      <CartContainer visible={cartOpen}>
        <CartHeader>
          <CartHeading>{dictionary.cartName}</CartHeading>

          <CartCloseButton onClick={() => dispatch(setCartOpen(false))}>
            <CloseIcon color='#18100E' />
          </CartCloseButton>
        </CartHeader>

        {amountOfProducts === 0 ? (
          <EmptyCart>{dictionary.emptyCartText}</EmptyCart>
        ) : (
          <CartInner>
            <ProgressBar
              color={cartProgressBarColor}
              goal={cartFreeShippingAmount}
              value={subtotal}
              progressMsg={cartFreeShippingUnreachedMessage}
              successMsg={cartFreeShippingReachedMessage}
            />

            <LineItemsList>
              {cartItems.map((lineItem, i) => (
                <li key={`lineitem-${i}`}>
                  <LineItem lineItem={lineItem} />
                </li>
              ))}
            </LineItemsList>

            <Checkout>
              <CheckoutInformation>
                <RichText json={dictionary.cartShippingInformation.json} />
              </CheckoutInformation>
              <CheckoutButton href={webUrl}>
                {dictionary.checkOut} <span></span> €{subtotal.toFixed(2)}
              </CheckoutButton>
            </Checkout>

            <CartRecommendations
              heading={dictionary.cartRecommendationsHeading}
              recommendations={recommendations}
            />

            <CartFooter>
              <dt>{dictionary.subtotal}</dt>
              <dd>€{subtotal.toFixed(2)}</dd>
              <dt>{dictionary.shipping}</dt>
              <dd>
                {(subtotal / cartFreeShippingAmount) * 100 >= 100
                  ? dictionary.free
                  : `€${cartShippingCost.toFixed(2)}`}
              </dd>

              <dt>{dictionary.total}</dt>
              <dd>
                €
                {(subtotal / cartFreeShippingAmount) * 100 >= 100
                  ? subtotal.toFixed(2)
                  : (subtotal + cartShippingCost).toFixed(2)}
                {}
              </dd>
            </CartFooter>
          </CartInner>
        )}
      </CartContainer>
    </CartWrapper>
  )
}

export default Cart
