import React from 'react'
import styled from 'styled-components'
import { font, colors } from '../../styles'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'
import useReplaceLineItem from '../../hooks/useReplaceLineItem'

const Container = styled.article`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 146px auto;
`

const ImageWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Title = styled.h2`
  font-size: 20px;
  font-family: ${font.ben};
  text-transform: lowercase;
  line-height: 19px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.1em;
  padding-bottom: 0.14em;
  word-break: break-word;
  font-weight: normal;
`

const ContentWrapper = styled.div`
  padding: 4px 0 10px 16px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Description = styled.p`
  font-family: ${font.larsseit};
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${colors.brownDarkest};
  margin-top: calc(20px * -0.14);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.15em;
  padding-bottom: 0.14em;
  word-break: break-word;
`

const PriceContainer = styled.p`
  color: #9b9b9b;
  font-size: 14px;
  line-height: 24px;
  margin-top: calc(14px * -0.14);

  span + span {
    color: ${colors.orange};
    margin-left: 9px;
  }
`

const StrikeTrough = styled.span`
  text-decoration: line-through;
`

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ControlContainer = styled.div`
  display: grid;
  width: 96px;
  height: 35px;
  border-radius: 100px;
  grid-template-columns: 1fr 1fr 1fr;
  border: solid 1px #000000;
  overflow: hidden;
`

const ControlButton = styled.button`
  position: relative;
  height: 100%;

  &::after,
  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 9px;
    height: 2px;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000000;
  }
`

const ControlDecrementButton = styled(ControlButton)`
  &::after {
    display: block;
    left: 10px;
  }
`

const ControlIncrementButton = styled(ControlButton)`
  &::after,
  &::before {
    display: block;
    right: 10px;
  }

  &::before {
    transform: rotate(90deg);
  }
`

const ControlQuantity = styled.p`
  width: 100%;
  font-family: ${font.ben};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
`

const ControlRemoveButton = styled.button`
  margin-left: 19px;
  font-family: ${font.larsseit};
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: #9b9b9b;
`

// Helper(s)
const extractData = lineItem => ({
  img: lineItem?.variant?.image?.transformedSrc || '',
  title: lineItem?.title,
  price: lineItem?.variant.price.amount,
  compareAtPrice: lineItem?.variant?.compareAtPrice?.amount,
  variantId: lineItem?.variant.id,
  quantity: lineItem?.quantity,
  description: lineItem?.product.productDescriptionShort,
})

const LineItem = ({ lineItem }) => {
  const dictionary = useDictionaryCtx()
  const [replaceLineItem] = useReplaceLineItem()

  const {
    img,
    title,
    price,
    compareAtPrice,
    variantId,
    quantity,
    description,
  } = extractData(lineItem)

  return (
    <Container>
      <ImageWrapper>
        <Image src={img} />
      </ImageWrapper>

      <ContentWrapper>
        <div>
          <Title title={title}>{title}</Title>

          {description && (
            <Description title={description}>{description}</Description>
          )}

          <PriceContainer>
            {compareAtPrice && (
              <StrikeTrough>€{Number(compareAtPrice).toFixed(2)} </StrikeTrough>
            )}

            <span>€{Number(price).toFixed(2)}</span>
          </PriceContainer>
        </div>
        <div>
          <ControlWrapper>
            <ControlContainer>
              <ControlDecrementButton
                type='button'
                onClick={() => {
                  replaceLineItem({
                    variantId,
                    quantity: quantity - 1,
                  })
                }}
              />
              <ControlQuantity>{quantity}</ControlQuantity>
              <ControlIncrementButton
                type='button'
                onClick={() => {
                  replaceLineItem({
                    variantId,
                    quantity: quantity + 1,
                  })
                }}
              />
            </ControlContainer>

            <ControlRemoveButton
              onClick={() =>
                replaceLineItem({
                  variantId,
                  quantity: 0,
                })
              }
            >
              {dictionary.lineItemRemove}
            </ControlRemoveButton>
          </ControlWrapper>
        </div>
      </ContentWrapper>
    </Container>
  )
}

export default LineItem
