import React from 'react'

const AccountIcon = props => (
  <svg
    width='17'
    height='18'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.133 9.5C9.23613 9.5 8.80488 10 7.33301 10C5.86113 10 5.43301 9.5 4.53301 9.5C2.21426 9.5 0.333008 11.3813 0.333008 13.7V14.5C0.333008 15.3281 1.00488 16 1.83301 16H12.833C13.6611 16 14.333 15.3281 14.333 14.5V13.7C14.333 11.3813 12.4518 9.5 10.133 9.5ZM12.833 14.5H1.83301V13.7C1.83301 12.2125 3.04551 11 4.53301 11C4.98926 11 5.72988 11.5 7.33301 11.5C8.94863 11.5 9.67363 11 10.133 11C11.6205 11 12.833 12.2125 12.833 13.7V14.5ZM7.33301 9C9.81738 9 11.833 6.98438 11.833 4.5C11.833 2.01562 9.81738 0 7.33301 0C4.84863 0 2.83301 2.01562 2.83301 4.5C2.83301 6.98438 4.84863 9 7.33301 9ZM7.33301 1.5C8.98613 1.5 10.333 2.84687 10.333 4.5C10.333 6.15312 8.98613 7.5 7.33301 7.5C5.67988 7.5 4.33301 6.15312 4.33301 4.5C4.33301 2.84687 5.67988 1.5 7.33301 1.5Z'
      fill='#18100E'
    />
  </svg>
)

export default AccountIcon
