import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setCartOpen, setSearchOpen } from '../../actions'
import {
  mobileVW,
  desktopBreakpoint,
  colors,
  font,
  letterSpacing,
  headerHeight,
} from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../../hooks/context'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Logo from '../graphics/Logo'
import Bag from '../graphics/Bag'
import AccountLink from './AccountLink'
import SearchIcon from '../graphics/SearchIcon'
import LogoOutlineSmall from '../graphics/LogoOutlineSmall'
import RichText from '../shared/RichText'
import CategoryFrame from '../shared/categoryFrame'
import LangSwitch from '../shared/LangSwitch'
import Facebook from '../graphics/Facebook'
import Instagram from '../graphics/Instagram'

const StyledHeader = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 17;
  transition: opacity 0.5s ease-in-out;
  background-color: ${props => (props.active ? colors.brownDarkest : 'white')};
  box-shadow: 0px 4px 3px rgba(225, 207, 191, 0.15);

  @media (min-width: ${desktopBreakpoint}) {
    background: white;
    -khtml-opacity: ${props =>
      props.isTop ? '100%' : props.fade && !props.isTop ? '0%' : '100%'};
    opacity: ${props =>
      props.isTop ? '100%' : props.fade && !props.isTop ? '0%' : '100%'};
    pointer-events: ${props =>
      props.isTop ? 'auto' : props.fade && !props.isTop ? 'none' : 'auto'};
  }
`

const Navigation = styled.div`
  z-index: 2;
  height: ${headerHeight.mobile}px;
  padding: 24px 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto;
    padding: 0;
    height: ${headerHeight.desktop}px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 90%;
    height: 1px;
    background: ${props => (props.white ? colors.orange : 'transparent')};

    @media (min-width: ${desktopBreakpoint}) {
      width: 94.7916666667vw;
    }
  }
`

const HamburgerMenu = styled.button`
  height: ${props => (props.active ? 15 : 14)}px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    width: ${props => (props.active ? 20 : 18)}px;
    height: 2px;
    background: ${props => props.color};
    transition: 0.1s ease-in;

    &:first-child {
      transform-origin: top left;
      transform: ${props => (props.active ? 'rotate(45deg)' : null)};
    }

    &:nth-child(2) {
      width: 14px;
      transform-origin: top left;
      display: ${props => (props.active ? 'none' : 'block')};
    }

    &:nth-child(3) {
      transform-origin: bottom left;
      transform: ${props => (props.active ? 'rotate(-45deg)' : null)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const LogoWrapper = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  padding: ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
  }

  svg {
    path {
      fill: ${props => props.color};
    }
  }
`

const CartButton = styled.button`
  .cart_button {
    display: flex;
    align-items: center;

    svg {
      margin-bottom: -2px;

      path {
        stroke: ${props => (props.active ? 'white' : colors.brownDarkest)};
      }

      @media (min-width: ${desktopBreakpoint}) {
        margin-bottom: 0;

        path {
          stroke: ${colors.brownDarkest};
        }
      }
    }
  }
`

const MenuDesktop = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    padding-left: 56px;

    a {
      margin-left: ;
    }
  }
`

const AccountLinkWrapper = styled.div`
  color: ${props => props.color};
  margin-right: ${mobileVW(20)};

  margin-left: auto;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
    font-family: ${font.trio};
    text-transform: uppercase;
  }

  a {
    display: flex;
    align-items: center;
  }

  span {
    display: none;
  }

  svg {
    path {
      fill: ${props => (props.active ? 'white' : colors.brownDarkest)};
      stroke: none;
    }
    @media (min-width: ${desktopBreakpoint}) {
      path {
        fill: ${colors.brownDarkest} !important;
      }
    }
  }
`

const SearchButton = styled.button`
  position: absolute;
  left: ${mobileVW(65)};
  top: calc(${headerHeight.mobile}px / 2);
  transform: translateY(-50%);

  @media (min-width: ${desktopBreakpoint}) {
    display: inline-block;
    position: initial;
    margin: 0;
    font-size: 12px;
    font-family: ${font.trio};
    text-transform: uppercase;
    transform: none;
  }

  svg {
    path {
      stroke: ${props => props.color};
    }

    @media (min-width: ${desktopBreakpoint}) {
      path {
        stroke: ${colors.brownDarkest};
      }
    }
  }
`

const Dropdown = styled.div`
  z-index: 1;
  position: relative;

  height: 100vh;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-weight: normal;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  svg {
    display: none;
  }

  > svg {
    display: block;
    position: absolute;
    top: calc((100vh - ${mobileVW(headerHeight.mobile + 68)}) / 2);
    right: ${mobileVW(30)};
    transform: translateY(-50%);
    width: ${mobileVW(87)};
    @media (min-width: ${desktopBreakpoint}) {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 36px 56px;
    height: auto;
    background-color: ${colors.sand};
    min-height: unset;
    overflow-y: scroll;
  }
`

const DropdownInner = styled.div`
  @media (max-width: ${desktopBreakpoint}) {
    overflow-y: scroll;
    height: calc(100vh - ${mobileVW(headerHeight.mobile + 68)});
    padding: 0 ${mobileVW(30)} ${mobileVW(120)};

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const DropdownFooter = styled.div`
  display: flex;
  height: ${mobileVW(68)};
  padding: ${mobileVW(24)} ${mobileVW(32)};
  width: 100%;
  border-top: solid 1px ${colors.beigeLight};
  position: sticky;
  bottom: env(safe-area-inset-bottom);
  background: ${colors.brownDarkest};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const DropdownSocialContainer = styled.div`
  margin-right: auto;
  display: flex;
`

const SocialLink = styled.a`
  &:not(:last-child) {
    margin-right: ${mobileVW(36)};
  }

  svg {
    display: block;
    width: ${mobileVW(20)};

    @media (min-width: ${desktopBreakpoint}) {
      width: 20px;
    }
  }

  path {
    fill: ${colors.beigeUltraLight};
  }
`

const DropdownWrapper = styled.div`
  display: block;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
`

const Shop = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.visible ? 'block' : 'none')};
    height: 100%;
  }
`
const Connect = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.visible ? 'block' : 'none')};
    height: 100%;
  }
`

const Learn = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.visible ? 'block' : 'none')};
    height: 100%;
  }
`

const Title = styled.button`
  position: relative;
  display: block;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: ${mobileVW(30)};
  font-weight: normal;
  color: ${colors.beigeLight};
  margin-top: ${mobileVW(56)};

  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.desktop ? 'block' : 'none')};
    position: relative;
    font-size: 18px;
    line-height: 1;
    margin-top: 0;
    margin-right: 40px;
    color: ${props =>
      props.active ? `${colors.orangeLight}` : `${colors.brownDarkest}`};
    padding-bottom: 1px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      border-bottom: ${props =>
        props.active
          ? `solid 1px ${colors.orangeLight}`
          : `solid 1px ${colors.brownDarkest}`};
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`

const ShortDescription = styled.h2`
  display: none;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    font-size: 28px;
    margin-bottom: 20px;
    font-family: ${font.ben};
    color: ${colors.beigeDark};
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  color: ${colors.beigeUltraLight};
  text-align: left;
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(6)};
  margin-top: ${mobileVW(24)};
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    text-transform: lowercase;
    margin-top: 0;
    font-size: 22px;
    width: 161px;
    height: 161px;
    margin-right: 7px;
    border-radius: 6px;
    overflow: hidden;
  }
`

const ConnectLink = styled(Link)`
  display: flex;
  color: ${colors.beigeUltraLight};
  text-align: left;
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(6)};
  margin-top: ${mobileVW(24)};
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    font-size: 21px;
    width: 232px;
    height: auto;
    margin-right: 16px;
  }
`

const StyledImage = styled.div`
  // min-width: ${mobileVW(90)};
  // margin-right: ${mobileVW(20)};
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }
`

const ConnectImage = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    width: 100%;
    height: 180px;
    border-radius: 6px;
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }
`

const AllLink = styled(Link)`
  display: flex;
  color: ${colors.beigeUltraLight};
  text-align: left;
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(6)};
  margin-top: ${mobileVW(24)};
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    color: ${colors.brownDarkest};
    font-size: 18px;
    margin-top: 0;

    svg {
      display: block;
      position: initial;
      width: 28px;
    }
  }
`

const wiggle = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`

const ButtonWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: 35px;
    right: 57px;
    display: flex;
    align-items: flex-end;

    &:hover {
      svg {
        animation: ${wiggle} 0.6s linear;
      }
    }
  }
`

const AllTitle = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    margin-right: 8px;
  }
`

const Description = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    font-family: ${font.larsseit};
    font-size: 16px;
    display: block;
    color: ${colors.brownDarkest};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 122%;
    margin-top: 8px;
  }
`

const ShopTitle = styled.h2`
  line-height: 1.3;

  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
`

const ConnectTitle = styled.h2`
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-family: ${font.ben};
    font-size: 22px;
    margin-top: 26px;
    color: ${colors.brownDarkest};
    font-weight: normal;
  }
`

const Overlay = styled.div`
  z-index: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${props => (props.visible ? 'block' : 'none')};
`

const NavButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${desktopBreakpoint}) {
    justify-content: space-between;
    // width: 140px;
    margin-right: 44px;

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
    }
  }

  .cart_button {
    display: flex;
    align-items: center;

    span {
      color: ${props => (props.active ? 'white' : `${colors.brownDarkest}`)};
      display: none;
      font-family: ${font.trio};
      text-transform: uppercase;
      font-size: ${mobileVW(10)};
      margin-left: ${mobileVW(10)};
      letter-spacing: ${letterSpacing(6)};
      font-weight: 400;
      @media (min-width: ${desktopBreakpoint}) {
        display: inline-block;
        font-size: 12px;
        margin-left: 10px;
        color: ${colors.brownDarkest};
      }
    }

    svg {
      width: ${mobileVW(32)};

      path {
        stroke: ${props => (props.active ? `white` : `${colors.brownDarkest}`)};
      }

      @media (min-width: ${desktopBreakpoint}) {
        width: 32px;
        path {
          stroke: ${colors.brownDarkest};
        }
      }
    }
  }
`

const Header = () => {
  const {
    node_locale,
    headerLearnMenuV2,
    headerShopMenu,
    headerConnectMenu,
    instagramLink,
    facebookLink,
    headerStyle,
    videoMenu,
  } = useLayoutCtx()

  const dictionary = useDictionaryCtx()
  const [openDropdown, setOpenDropdown] = useState(false)
  const [headerColor, setHeaderColor] = useState(colors.brownDarkest)
  const [openDesktopMenu, setOpenDesktopMenu] = useState('learn')
  const [openLearnMenu, setOpenLearnMenu] = useState(false)
  const [openConnectMenu, setOpenConnectMenu] = useState(false)
  const [openShopMenu, setOpenShopMenu] = useState(false)
  const dispatch = useDispatch()
  const cartOpen = useSelector(({ cartOpen }) => cartOpen)
  const searchOpen = useSelector(({ searchOpen }) => searchOpen)

  useLockBodyScroll(openDropdown)

  return (
    <StyledHeader active={openDropdown ? 'true' : undefined}>
      <Navigation>
        <HamburgerMenu
          type='button'
          aria-label='open menu'
          active={openDropdown}
          color={openDropdown ? 'white' : headerColor}
          onClick={() => {
            if (openDropdown) {
              setOpenDropdown(false)
            } else if (cartOpen) {
              dispatch(setCartOpen(false))
            } else if (searchOpen) {
              dispatch(setSearchOpen(false))
            } else {
              setOpenDropdown(true)
            }
          }}
        >
          <div />
          <div />
          <div />
        </HamburgerMenu>
        <MenuDesktop>
          <Title
            desktop
            type='button'
            color={
              openDropdown || cartOpen || searchOpen
                ? colors.brownDarkest
                : headerColor
            }
            aria-label='open learn'
            active={openDesktopMenu === 'learn' && openDropdown === true}
            onMouseOver={() => {
              setOpenDesktopMenu('learn')
              setOpenDropdown(true)
              if (cartOpen) {
                dispatch(setCartOpen(false))
              }
              if (searchOpen) {
                dispatch(setSearchOpen(false))
              }
            }}
          >
            <Link
              to={getPathname(node_locale, 'learn-all')}
              onClick={() => setOpenDropdown(false)}
            >
              <span>{dictionary.learn}</span>
            </Link>
          </Title>
          <Title
            desktop
            type='button'
            aria-label='open shop'
            active={openDesktopMenu === 'shop' && openDropdown === true}
            onMouseOver={() => {
              setOpenDesktopMenu('shop')
              setOpenDropdown(true)
              if (cartOpen) {
                dispatch(setCartOpen(false))
              }
              if (searchOpen) {
                dispatch(setSearchOpen(false))
              }
            }}
          >
            <Link
              to={getPathname(
                node_locale,
                headerShopMenu[headerShopMenu.length - 1].slug,
              )}
              onClick={() => setOpenDropdown(false)}
            >
              <span>{dictionary.shop}</span>
            </Link>
          </Title>

          <Title
            desktop
            type='button'
            color={
              openDropdown || cartOpen || searchOpen
                ? colors.brownDarkest
                : headerColor
            }
            aria-label='open connect'
            active={openDesktopMenu === 'connect' && openDropdown === true}
            onMouseOver={() => {
              setOpenDesktopMenu('connect')
              setOpenDropdown(true)
              if (cartOpen) {
                dispatch(setCartOpen(false))
              }
              if (searchOpen) {
                dispatch(setSearchOpen(false))
              }
            }}
          >
            <span>{dictionary.connect}</span>
          </Title>
        </MenuDesktop>
        <LogoWrapper
          color={colors.orange}
          to={getPathname(node_locale)}
          onClick={() => {
            if (openDropdown) {
              setOpenDropdown(false)
            }
            if (cartOpen) {
              dispatch(setCartOpen(false))
            }
            if (searchOpen) {
              dispatch(setSearchOpen(false))
            }
          }}
        >
          <Logo />
        </LogoWrapper>
        <NavButtons active={openDropdown}>
          <SearchButton
            color={openDropdown ? 'white' : headerColor}
            type='button'
            aria-label='open cart'
            onClick={() => {
              dispatch(setSearchOpen(!searchOpen))
              if (openDropdown) {
                setOpenDropdown(false)
              }
              if (cartOpen) {
                dispatch(setCartOpen(false))
              }
            }}
          >
            <SearchIcon />
          </SearchButton>

          <LangSwitch desktop={true} />

          <AccountLinkWrapper
            active={openDropdown}
            onClick={() => {
              if (openDropdown) {
                setOpenDropdown(false)
              }
              if (cartOpen) {
                dispatch(setCartOpen(false))
              }
              if (searchOpen) {
                dispatch(setSearchOpen(false))
              }
            }}
          >
            <AccountLink icon />
          </AccountLinkWrapper>
          <CartButton
            active={openDropdown}
            type='button'
            aria-label='open cart'
            active={openDropdown}
            onClick={() => {
              dispatch(setCartOpen(!cartOpen))
              if (openDropdown) {
                setOpenDropdown(false)
              }
              if (searchOpen) {
                dispatch(setSearchOpen(false))
              }
            }}
          >
            <div className='cart_button'>
              <Bag />
              {/* {amountOfProducts && amountOfProducts > 0 ? (
                <span>(amountOfProducts)</span>
              ) : null} */}
            </div>
          </CartButton>
        </NavButtons>
      </Navigation>
      <Dropdown visible={openDropdown}>
        <LogoOutlineSmall />
        <DropdownInner>
          <Learn visible={openDesktopMenu === 'learn'}>
            <Title
              type='button'
              aria-label='open dropdown'
              active={openLearnMenu}
              onClick={() => setOpenDropdown(false)}
            >
              <Link to={getPathname(node_locale, 'learn-all')}>
                <span>{dictionary.learn}</span>
              </Link>
            </Title>
            <ShortDescription>
              {dictionary.learnMenuDescription}
            </ShortDescription>
            <DropdownWrapper visible={openLearnMenu ? true : undefined}>
              {headerLearnMenuV2.map(({ link, title }, i) => (
                <CategoryFrame key={i}>
                  <StyledLink
                    onClick={() => setOpenDropdown(false)}
                    key={title}
                    to={getPathname(node_locale, link[0].slug)}
                  >
                    <StyledImage>
                      <Image fluid={link[0].heroImageMobile?.fluid} />
                    </StyledImage>
                    <ShopTitle>{title}</ShopTitle>
                  </StyledLink>
                </CategoryFrame>
              ))}
            </DropdownWrapper>
          </Learn>
          <Shop visible={openDesktopMenu === 'shop'}>
            <Title
              type='button'
              aria-label='open dropdown'
              active={openShopMenu}
              onClick={() => setOpenDropdown(false)}
            >
              <Link
                to={getPathname(
                  node_locale,
                  headerShopMenu[headerShopMenu.length - 1].slug,
                )}
              >
                <span>{dictionary.shop}</span>
              </Link>
            </Title>
            <ShortDescription>
              {dictionary.shopMenuDescription}
            </ShortDescription>
            <DropdownWrapper visible={openShopMenu ? true : undefined}>
              {headerShopMenu.map(({ slug, title, thumbnail }, i) =>
                i < headerShopMenu.length - 1 ? (
                  <CategoryFrame key={i}>
                    <StyledLink
                      onClick={() => setOpenDropdown(false)}
                      key={title}
                      to={getPathname(node_locale, slug)}
                    >
                      <StyledImage>
                        <Image fluid={thumbnail?.fluid} />
                      </StyledImage>
                      <ShopTitle>{title}</ShopTitle>
                    </StyledLink>
                  </CategoryFrame>
                ) : null,
              )}
            </DropdownWrapper>
          </Shop>
          <Connect visible={openDesktopMenu === 'connect'}>
            <Title
              type='button'
              aria-label='open dropdown'
              active={openConnectMenu}
            >
              <span>{dictionary.connect}</span>
            </Title>
            <ShortDescription>
              {dictionary.connectMenuDescription}
            </ShortDescription>
            <DropdownWrapper visible={openConnectMenu ? true : undefined}>
              {headerConnectMenu.map(
                ({
                  slug,
                  externalLink,
                  title,
                  heroImageMobile,
                  thumbnail,
                  descriptionLong,
                  description,
                  header,
                  introductionText,
                }) =>
                  !externalLink && (
                    <ConnectLink
                      onClick={() => setOpenDropdown(false)}
                      key={slug}
                      to={getPathname(node_locale, slug)}
                    >
                      <ConnectImage>
                        <Image
                          fluid={
                            thumbnail?.fluid ||
                            heroImageMobile?.fluid ||
                            mobileBannerImage?.fluid
                          }
                        />
                      </ConnectImage>
                      <ConnectTitle>{title || header}</ConnectTitle>
                      <Description>
                        {introductionText || descriptionLong ? (
                          <RichText
                            json={
                              introductionText?.json || descriptionLong?.json
                            }
                          />
                        ) : (
                          <span>{description}</span>
                        )}
                      </Description>
                    </ConnectLink>
                  ),
              )}
            </DropdownWrapper>
          </Connect>
        </DropdownInner>

        <DropdownFooter>
          <DropdownSocialContainer>
            <SocialLink
              target='_blank'
              aria-label='Instagram'
              rel='noopener'
              href={instagramLink}
            >
              <Instagram />
            </SocialLink>
            <SocialLink
              target='_blank'
              href={facebookLink}
              aria-label='Facebook'
              rel='noopener'
            >
              <Facebook />
            </SocialLink>
          </DropdownSocialContainer>
          <LangSwitch onChange={() => setOpenDropdown(false)} />
        </DropdownFooter>
      </Dropdown>
      <Overlay
        visible={openDropdown}
        onClick={() => {
          setOpenDropdown(false)
        }}
      />
    </StyledHeader>
  )
}

export default Header
