import React from 'react'

const ArrowFlat = ({ color = '#ff7002' }) => (
  <svg
    width='12'
    height='8'
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 1.66675L6.33333 6.33341L1.66667 1.66675'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowFlat
