import React from 'react'
import styled from 'styled-components'

import { desktopBreakpoint, desktopVW } from '../../styles'

const Frame = styled.div`
  height: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    > *:first-child {
      box-shadow: 0px 0px 8px rgba(101, 71, 63, 0.1);
    }

    .gatsby-image-wrapper {
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
      > *:first-child {
        box-shadow: 0px 0px 10px rgba(101, 71, 63, 0.2);
      }
    }
  }
`

const CategoryFrame = ({ children }) => {
  return <Frame>{children}</Frame>
}

export default CategoryFrame
