import { gql } from 'apollo-boost'
import { checkout } from '../fragments/shopify'

const mutation = gql`
  mutation checkoutAttributesUpdateV2(
    $input: CheckoutAttributesUpdateV2Input!
    $checkoutId: ID!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ...ShopifyCheckout
      }
    }
  }
  ${checkout}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/mutation/checkoutattributesupdatev2
 *
 * @param {{lineItems: [CheckoutLineItemInput], checkoutId: ID}} variables
 */
const updateCheckoutAttributes = (client, variables) =>
  client.mutate({ mutation, variables })

export default updateCheckoutAttributes
