import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

const Auth = ({ uiConfig }) => {
  const [firebaseui, setFirebaseui] = useState()
  useEffect(() => {
    import('firebaseui').then(setFirebaseui)
  }, [])

  if (!firebaseui) {
    return null
  }

  return (
    <div>
      <StyledFirebaseAuth
        uiConfig={{
          credentialHelper: firebaseui.auth.CredentialHelper.NONE,
          ...uiConfig,
        }}
        firebaseAuth={firebase.auth()}
      />
    </div>
  )
}

export default Auth
