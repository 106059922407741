import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import queryProductByHandle from '../shopify/queryProductByHandle'

// Hooks
import useStoreCtx from './context/useStoreCtx'

/**
 *
 * @param {string} handle
 * @param {string} [store]
 *
 * @return {{product: Object, loading: boolean, error: Error}}
 */
const useShopifyProduct = (handle, storeSubdomain) => {
  const [state, setState] = useState({
    product: null,
    loading: false,
    error: handle ? null : new Error('"handle" is missing.'),
  })

  const stores = useStoreCtx()
  const activeStoreSubdomain = useSelector(
    ({ storeSubdomain }) => storeSubdomain,
  )
  const { client } = stores[storeSubdomain || activeStoreSubdomain]

  useEffect(() => {
    if (handle) {
      const queryShopifyProduct = async () => {
        setState({ product: null, loading: true, error: null })

        try {
          const {
            data: { product },
          } = await queryProductByHandle(client, { handle })
          setState({ product, loading: false, error: null })
        } catch (error) {
          setState({ product: null, loading: false, error })
        }
      }

      queryShopifyProduct()
    }
  }, [handle, client])

  return state
}

export default useShopifyProduct
