import React from 'react'
import firebase from 'firebase/app'
import Provider from './src/components/shared/Provider'
import { stores, firebaseConfig } from './fill-config'
import { LayoutContext, DictionaryContext, ShopContext } from './src/contexts'
import Layout from './src/components/layout/Layout'
import { useCheckoutHydration } from './src/hooks/useCheckoutHydration'
import useReviewedProducts from './src/hooks/useReviewedProducts'

export const onClientEntry = () => {
  firebase.initializeApp(firebaseConfig)
}

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.endsWith('/auth')) return

  sessionStorage.setItem('CYCLE_LOGIN_REF', window.location.pathname)
}

const CheckoutHydration = ({ children }) => {
  useCheckoutHydration()
  return children
}

export const wrapRootElement = ({ element }) => (
  <Provider stores={stores}>
    <CheckoutHydration>{element}</CheckoutHydration>
  </Provider>
)

const ContextProvider = ({ shop, layout, dictionary, children }) => {
  const products = useReviewedProducts(shop.products)

  return (
    <ShopContext.Provider value={{ products }}>
      <LayoutContext.Provider value={layout}>
        <DictionaryContext.Provider value={dictionary}>
          {children}
        </DictionaryContext.Provider>
      </LayoutContext.Provider>
    </ShopContext.Provider>
  )
}

export const wrapPageElement = ({ element, props: { pageContext } }) => {
  window.Trengo = window.Trengo || {}
  window.Trengo.key = 'ieoYjMhVNF1XAWjPMX70'
  ;(function(d, script, t) {
    script = d.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://cdn.widget.trengo.eu/embed.js'
    d.getElementsByTagName('head')[0].appendChild(script)
  })(document)

  return (
    <ContextProvider {...pageContext}>
      <Layout>{element}</Layout>
    </ContextProvider>
  )
}
