import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchOpen } from '../../actions'
import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  InstantSearch,
  SearchBox,
  connectHits,
  connectMenu,
  Stats,
} from 'react-instantsearch-dom'
import {
  font,
  mobileVW,
  colors,
  letterSpacing,
  desktopBreakpoint,
  headerHeight,
} from '../../styles'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import Close from '../graphics/Close'
import ResultCard from '../../components/search/resultCard'
import ArrowFilled from '../../components/graphics/ArrowFilled'

const StyledSearch = styled.section`
  z-index: 10;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: ${headerHeight.mobile}px 0 0;
  background: white;
  font-family: ${font.larsseit};
  letter-spacing: ${letterSpacing(6)};
  color: ${colors.orange};
  transition: transform 0.5s ease;
  transform: ${({ open }) => (open ? 'translateY(0%)' : 'translateY(-200%)')};

  .ais-Stats {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: ${mobileVW(25)};
    margin: ${mobileVW(19)} auto ${mobileVW(14)} 0;
    padding-left: ${mobileVW(19)};
    font-weight: normal;
    color: ${colors.brownDarkest};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 25px;
      margin: 32px auto 12px 0;
      padding-left: 0;
    }
  }

  .ais-SearchBox-input {
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 75px 0 0;
    max-height: 537px;
  }
`

const StyledInput = styled.div`
  border-radius: 0;
  box-shadow: 0px 0px 6.75px rgba(101, 71, 63, 0.1);
  button {
    display: none;
  }
  form {
    max-width: 73vw;
    margin: auto;
    border-radius: 0;
    @media (min-width: ${desktopBreakpoint}) {
      max-width: 25vw;
    }
  }

  input {
    padding: ${mobileVW(20)} 0 ${mobileVW(21)} ${mobileVW(40)};
    background: transparent;
    width: 100%;
    font-size: 16px;
    color: ${colors.brownDarkest};
    border: none;
    text-align: left;
    border-radius: 0 !important;

    @media (min-width: ${desktopBreakpoint}) {
      padding: 15px;
      font-size: 24px;
    }

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: black;
      opacity: 0.3;
    }
  }
`

const Results = styled.div`
  position: relative;
  padding: 0 ${mobileVW(12)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 161px 0 0;
  }
`

const Scroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  max-height: 63vh;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 40px 0;
    display: flex;
    padding-top: 5px;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 325px;
  }
`

const NoResult = styled.div`
  font-size: ${mobileVW(17)};
  line-height: ${mobileVW(21)};
  text-align: center;
  width: 100%;
  margin-bottom: ${mobileVW(150)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 150px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: ${mobileVW(100)};
  left: ${mobileVW(25)};
  height: ${mobileVW(14)};
  width: ${mobileVW(14)};

  svg {
    height: 100%;
    width: 100%;
    path {
      stroke: ${colors.orangeLight};
      stroke-width: 2px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    top: 93px;
    left: auto;
    right: 180px;
    height: 20px;
    width: 20px;
  }
`

const Gradient = styled.div`
  height: ${mobileVW(200)};
  bottom: ${mobileVW(-200)};
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(rgb(255, 255, 255), transparent);
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const Overlay = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  display: ${props => (props.visible ? 'block' : 'none')};
`

const Container = styled.div`
  padding: 0 ${mobileVW(25)};
  margin-top: ${mobileVW(25)};
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: column;
    margin-top: 76px;
    padding: 0 26px 0 74px;
    min-width: 300px;
  }
`

const OptionWrapper = styled.div`
  margin-right: ${mobileVW(16)};
  margin-bottom: ${mobileVW(13)};
  height: ${mobileVW(25)};
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    margin-right: 0;
    height: 20px;
  }
  label {
    font-size: ${mobileVW(13)};
    text-transform: Capitalize;
    color: ${colors.orangeLight};
    border: solid ${mobileVW(1)} ${colors.orangeLight};
    padding: ${mobileVW(5)} ${mobileVW(10)} ${mobileVW(4)};
    line-height: 1;
    border-radius: ${mobileVW(100)};
    @media (min-width: ${desktopBreakpoint}) {
      position: relative;
      border: none;
      padding: 0;
      border-radius: 0;
      font-size: 13px;
      padding: 4px 0 0 31px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 19px;
        height: 19px;
        border: solid 1px ${colors.orangeLight};
      }
    }
  }
  input:checked + label {
    color: white;
    background-color: ${colors.orangeLight};
    @media (min-width: ${desktopBreakpoint}) {
      color: ${colors.orangeLight};
      background-color: white;
      &::before {
        background-color: ${colors.orangeLight};
      }
    }
  }

  input {
    opacity: 0;
    position: fixed;
    width: 0;
  }
`

const Wrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
  }
`

const MenuTitle = styled.div`
  display: none;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: ${font.ben};
    font-size: 20px;
    color: ${colors.brownDarkest};
    padding-bottom: 10px;
    margin-bottom: 17px;
    border-bottom: solid 1px ${colors.orangeLight};
    svg {
      width: 12px;
      margin-top: 5px;
      margin-right: 16px;
      transition: transform ease-in-out 0.2s;
      transform: ${props => (props.rotate ? 'rotate(180deg)' : 'none')};
    }
  }
`

const Dropdown = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.show ? 'flex' : 'none')};
    flex-wrap: nowrap;
    flex-direction: column;
    padding-left: 5px;
  }
`

const ResultsWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: 27px;
  }
`

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
)

const Hits = connectHits(({ hits }) => {
  const { searchNoResultsText } = useLayoutCtx()

  return (
    <Results>
      <Scroll>
        {hits.length >= 1 ? (
          <>
            {hits.map((hit, i) => {
              return <ResultCard key={i} hit={hit} />
            })}
          </>
        ) : (
          <NoResult>{searchNoResultsText}</NoResult>
        )}
      </Scroll>
    </Results>
  )
})

const MenuSelect = ({ items, currentRefinement, refine }) => {
  const refineSearch = e => {
    refine(
      currentRefinement == e.currentTarget.value ? '' : e.currentTarget.value,
    )
    e.currentTarget.checked = currentRefinement !== e.currentTarget.value
  }

  const [showOptions, setShowOptions] = useState(true)

  return (
    <Container>
      <MenuTitle
        rotate={!showOptions ? 'false' : undefined}
        onClick={() => setShowOptions(!showOptions)}
      >
        result type <ArrowFilled />
      </MenuTitle>
      <Dropdown show={showOptions}>
        {items.map((item, i) => (
          <OptionWrapper key={i}>
            <input
              type='radio'
              onClick={e => refineSearch(e)}
              id={item.label == 'product' ? 'producten' : item.label}
              value={item.isRefined ? currentRefinement : item.value}
              name='refineByType'
            />
            <label htmlFor={item.label == 'product' ? 'producten' : item.label}>
              {item.label == 'product' ? 'producten' : item.label}
            </label>
          </OptionWrapper>
        ))}
      </Dropdown>
    </Container>
  )
}

const CustomMenuSelect = connectMenu(MenuSelect)

const Search = () => {
  const dispatch = useDispatch()
  const { searchInputText, node_locale } = useLayoutCtx()
  const [searchType, setSearchType] = useState(null)
  const searchOpen = useSelector(({ searchOpen }) => searchOpen)

  const resetSearch = () => {
    dispatch(setSearchOpen(false))
    setSearchType(null)
  }

  return (
    <>
      <StyledSearch visible={searchOpen ? 'true' : undefined} open={searchOpen}>
        <CloseButton aria-label='close search' onClick={() => resetSearch()}>
          <Close />
        </CloseButton>
        {searchOpen && (
          <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME_PRODUCTION}
            searchClient={searchClient}
          >
            <Configure filters={`node_locale:${node_locale}`} />
            <StyledInput>
              <SearchBox
                translations={{ placeholder: searchInputText }}
                showReset
                autoFocus
                onChange={event => {
                  setSearchType(event.currentTarget.value)
                }}
              />
            </StyledInput>
            <Wrapper>
              <CustomMenuSelect attribute='contentType' />
              <ResultsWrapper>
                <Stats
                  translations={{
                    stats(nbHits) {
                      return searchType
                        ? `${nbHits} results found for: ${searchType}`
                        : 'most popular'
                    },
                  }}
                />
                <Hits />
              </ResultsWrapper>
            </Wrapper>
          </InstantSearch>
        )}

        <Gradient onClick={() => resetSearch()} />
      </StyledSearch>
      <Overlay visible={searchOpen} onClick={() => resetSearch()} />
    </>
  )
}

export default Search
