import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { replaceCheckout } from '../actions'
import updateCheckoutAttributes from '../shopify/updateCheckoutAttributes'

// Hooks
import useStoreCtx from './context/useStoreCtx'

const useUpdateCheckoutAttributes = () => {
  const [state, setState] = useState({
    customAttributes: null,
    loading: false,
    error: null,
  })

  const stores = useStoreCtx()
  const { storeSubdomain: activeStoreSubdomain, checkouts } = useSelector(
    ({ storeSubdomain, checkouts }) => ({
      storeSubdomain,
      checkouts,
    }),
  )
  const dispatch = useDispatch()

  const update = async (input, storeSubdomain = activeStoreSubdomain) => {
    if (input) {
      const checkout = checkouts[storeSubdomain]
      const { client } = stores[storeSubdomain]

      setState({ customAttributes: null, loading: true, error: null })
      try {
        const {
          data: {
            checkoutAttributesUpdateV2: {
              checkout: { id, webUrl, lineItems, customAttributes },
            },
          },
        } = await updateCheckoutAttributes(client, {
          checkoutId: checkout.id,
          input,
        })

        dispatch(
          replaceCheckout({
            ...checkout,
            id,
            webUrl,
            lineItems,
            customAttributes,
          }),
        )

        setState({
          customAttributes,
          loading: false,
          error: null,
        })
      } catch (error) {
        setState({
          customAttributes: null,
          loading: false,
          error,
        })
      }
    } else {
      setState({
        customAttributes: null,
        loading: false,
        error: new Error('The "input" is missing.'),
      })
    }
  }

  return [update, state]
}

export default useUpdateCheckoutAttributes
