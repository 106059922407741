import React, { useContext } from 'react'

// Context
import { LayoutContext } from '../../contexts'

const useLayoutCtx = () => {
  const context = useContext(LayoutContext)

  return context
}

export default useLayoutCtx
