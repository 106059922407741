import React, { useContext } from 'react'

// Context
import { DictionaryContext } from '../../contexts'

const useDictionaryCtx = () => {
  const context = useContext(DictionaryContext)

  return context
}

export default useDictionaryCtx
