export const desktopBreakpoint = `1024px`

export const mobileVW = px => {
  return `${(px / 375) * 100}vw`
}

export const desktopVW = px => {
  return `${(px / 1920) * 100}vw`
}

export const newDesktopVW = px => {
  return `${(px / 1620) * 100}vw`
}

export const letterSpacing = space => {
  return `${(space / 1000) * 1}em`
}

export const font = {
  nib: 'Nib',
  marby: 'Marby',
  trio: 'Trio',
  larsseit: 'Larsseit',
  ben: 'Benjamin',
  life: 'Life',
}

export const colors = {
  orange: '#DE742C',
  softOrange: '#FDA375',
  orangeLight: '#F3A679',
  purple: '#C8D5F0',
  pink: '#E5CAED',
  green: '#254F40',
  lightGreen: '#297258',
  babyBlue: '#E3EDFF',
  beige: '#F9F7EF',
  beigeLight: '#DDC8B6',
  beigeUltraLight: '#EEE3DA',
  beigeDark: '#C2A58E',
  brownDark: '#65473F',
  brownLight: '#CD8F6E',
  brownDarkest: '#18100E',
  orangeNew: '#DE742C',
  sand: '#EDEAE1',
  orangeButton: '#E8A980',
  red: '#de372b',
}

export const headerHeight = {
  mobile: 72,
  desktop: 80,
}
