import React, { useContext } from 'react'

// Context
import { ShopContext } from '../../contexts'

const useShopCtx = () => {
  const context = useContext(ShopContext)

  return context
}

export default useShopCtx
