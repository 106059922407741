import React from 'react'
import styled from 'styled-components'

import {
  mobileVW,
  newDesktopVW,
  colors,
  desktopBreakpoint,
  font,
} from '../../../styles'

const Label = styled.div`
  border-radius: ${mobileVW(100)};
  padding: ${mobileVW(6)} ${mobileVW(10)};
  background-color: ${props =>
    props.color ? props.color : `${colors.beigeUltraLight}`};
  font-family: ${font.larsseit};
  color: white;
  text-transform: uppercase;
  font-size: ${mobileVW(12)};
  line-height: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: max-content;
  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 100px;
    padding: 5px 10px;
    font-size: 14px;
    max-width: none;
  }
`

const ArticleLabel = ({ color, text }) => {
  return <Label color={color.includes('#') ? color : `#${color}`}>{text}</Label>
}

export default ArticleLabel
