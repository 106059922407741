import React, { useEffect, useState } from 'react'

const useReviewedProducts = _products => {
  const [products, setProducts] = useState(_products)

  useEffect(() => {
    ;(async function() {
      try {
        const reviewedProducts = await fetch('/.netlify/functions/yotpo')
          .then(res => res.json())
          .then(data => data)

        // Merge reviewed products with normal products for easy access
        const merged = products.map(product => {
          const found = reviewedProducts.message.find(
            ({ name }) => name === product.shopifyProduct.title,
          )

          return {
            ...product,
            reviewProductId: found ? found.external_product_id : null,
          }
        })

        setProducts(merged)
      } catch (error) {
        console.error(error.fn, error.body)
      }
    })()
  }, [])

  return products
}

export default useReviewedProducts
