import React, { useContext } from 'react'

// Context
import { StoreContext } from '../../contexts'

const useStoreCtx = () => {
  const context = useContext(StoreContext)

  return context
}

export default useStoreCtx
