import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import firebase from 'firebase/app'
import 'firebase/auth'

import {
  mobileVW,
  letterSpacing,
  colors,
  desktopBreakpoint,
  font,
  headerHeight,
} from '../../styles'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import Auth from './Auth'
import RichText from '../shared/RichText'
import Image from 'gatsby-image'

const Wrapper = styled.div`
  padding: ${headerHeight.mobile}px 0 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .circleSwiper {
    padding: ${mobileVW(22)} 0 ${mobileVW(29)};
    text-transform: none;
    @media (min-width: ${desktopBreakpoint}) {
      padding: 22px 0 29px;
      display: none;
    }
    h2 {
      font-size: ${mobileVW(24)};
      margin-bottom: ${mobileVW(27)};
      max-width: ${mobileVW(180)};
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 24px;
        margin-bottom: 27px;
        max-width: 215px;
      }
    }

    .swiper-slide {
      a {
        div {
          font-size: ${mobileVW(18)} !important;
          @media (min-width: ${desktopBreakpoint}) {
            font-size: 18px !important;
          }
        }
      }
    }
  }
  .firebaseui-card-content {
    padding: 0;
  }
  .mdl-button {
    font-family: ${font.larsseit};
    letter-spacing: ${letterSpacing(6)};
    line-height: ${mobileVW(16)};
    font-size: ${mobileVW(16)};
    padding: 0 ${mobileVW(16)};

    @media (min-width: ${desktopBreakpoint}) {
      line-height: 17px;
      font-size: 17px;
      padding: 0 16px;
    }
  }
  .firebaseui-idp-icon {
    height: ${mobileVW(16)};
    width: ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      height: 16px;
      width: 16px;
    }
  }

  .firebaseui-idp-text.firebaseui-idp-text-long {
    color: ${colors.brownDarkest} !important;
  }
  .firebaseui-idp-button {
    box-shadow: none;
    border: 1px solid ${colors.orangeLight};
    border-radius: ${mobileVW(2)};
    background: white;
    font-size: ${mobileVW(18)};
    padding: ${mobileVW(14)} ${mobileVW(24)};
    max-width: unset;
    justify-content: center;
    display: flex;
    min-height: unset;
    border-radius: ${mobileVW(100)};

    &.firebaseui-idp-google {
      .firebaseui-idp-text.firebaseui-idp-text-long {
        color: ${colors.orangeLight} !important;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      border-radius: 100px;
      width: 100%;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
      padding: 14px 24px;
      font-size: 18px;
    }
  }
  .firebaseui-button {
    margin-left: 0;
    height: ${mobileVW(36)};
    min-width: ${mobileVW(100)};
    @media (min-width: ${desktopBreakpoint}) {
      height: 40px;
      min-width: 100px;
    }
  }

  .firebaseui-idp-list > .firebaseui-list-item {
    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 15px;
    }
  }

  .firebaseui-idp-password,
  .firebaseui-idp-password:hover,
  .mdl-button.firebaseui-idp-password:active,
  .mdl-button.firebaseui-idp-password:focus {
    background-color: ${colors.orangeLight} !important;
    color: white;
  }
  .firebaseui-idp-password .firebaseui-idp-text,
  .firebaseui-idp-password:hover .firebaseui-idp-text,
  .mdl-button.firebaseui-idp-password:active .firebaseui-idp-text,
  .mdl-button.firebaseui-idp-password:focus .firebaseui-idp-text {
    color: white;
  }
  .firebaseui-idp-facebook,
  .firebaseui-idp-facebook:hover,
  .mdl-button.firebaseui-idp-facebook:active,
  .mdl-button.firebaseui-idp-facebook:focus {
    background-color: transparent;
  }

  .firebaseui-idp-icon-wrapper {
    @media (min-width: ${desktopBreakpoint}) {
      display: flex;
    }
  }

  .firebaseui-idp-text,
  .firebaseui-idp-google > .firebaseui-idp-text {
    color: ${colors.orange};
    font-size: ${mobileVW(18)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
    }
  }
  .firebaseui-card-header {
    padding: 0;
    border-bottom: none;
  }
  .firebaseui-title {
    color: ${colors.orange};
    text-align: left;
    font-size: ${mobileVW(20)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 20px !important;
    }
  }

  .firebaseui-textfield.mdl-textfield .firebaseui-input {
    font-family: ${font.larsseit};
    color: ${colors.orange};
    font-size: ${mobileVW(14)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
    }
    &::placeholder {
      color: ${colors.orange};
    }
  }
  .mdl-textfield {
    font-size: ${mobileVW(16)};
    padding: ${mobileVW(20)} 0;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      padding: 20px 0;
    }
  }
  .mdl-button:hover {
    background-color: none;
  }
  .mdl-textfield__label {
    top: 0;
    @media (min-width: ${desktopBreakpoint}) {
      top: 0;
    }
  }
  .firebaseui-error-wrapper {
    font-size: ${mobileVW(14)};
    min-height: ${mobileVW(14)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      min-height: 16px;
    }
  }
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: ${colors.orange};
    font-size: ${mobileVW(14)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
    }
  }
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    top: 0;
  }
  .firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    background-color: ${colors.orange};
    display: none;
  }
  .firebaseui-form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mdl-button--primary.mdl-button--primary {
    color: ${colors.orange};
    text-transform: normal;
    text-transform: inherit;
    border: 1px solid ${colors.orange};
    border-radius: ${mobileVW(28)};
  }
  .mdl-button--raised.mdl-button--colored {
    background: ${colors.orange};
    text-transform: normal;
    text-transform: inherit;
    border: 1px solid ${colors.orange};
    border-radius: ${mobileVW(28)};
    box-shadow: none;
  }
  .mdl-button--raised.mdl-button--colored:active,
  .mdl-button--raised.mdl-button--colored:focus:not(:active),
  .mdl-button--raised.mdl-button--colored:hover {
    background: ${colors.orange};
  }
  .firebaseui-container {
    font-family: ${font.larsseit};
  }
  .firebaseui-text {
    color: ${colors.orange};
    text-align: left;
    letter-spacing: ${letterSpacing(6)};
    font-size: ${mobileVW(12)};
    margin-bottom: ${mobileVW(20)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .firebaseui-subtitle,
  .firebaseui-title {
    color: ${colors.orange};
    text-align: left;
    font-size: ${mobileVW(14)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
    }
  }

  .firebaseui-title {
    font-size: ${mobileVW(25)};
    line-height: 1;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 40px;
    }
  }
  .firebaseui-label {
    color: ${colors.orange};
    font-size: ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
    }
  }
  .mdl-spinner--single-color .mdl-spinner__layer-1,
  .mdl-spinner--single-color .mdl-spinner__layer-2,
  .mdl-spinner--single-color .mdl-spinner__layer-3,
  .mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: ${colors.orange};
  }
  .mdl-button--raised:focus:not(:active),
  .mdl-button--raised:active {
    box-shadow: none;
  }
  .mdl-progress.mdl-progress--indeterminate > .bar1,
  .mdl-progress.mdl-progress__indeterminate > .bar1,
  .mdl-progress.mdl-progress--indeterminate > .bar3,
  .mdl-progress.mdl-progress__indeterminate > .bar3 {
    background-color: ${colors.orange};
  }
  .firebaseui-info-bar {
    margin-top: 20px;
  }

  .mdl-shadow--2dp {
    box-shadow: none;
  }

  .mdl-progress {
    height: 5px;
  }

  div.mdl-progress::after {
    color: ${colors.orange};
    content: 'Authenticeren';
    display: block;
    margin: 20px auto;
    text-align: left;
    font-size: ${mobileVW(13)};

    @media (min-width: ${desktopBreakpoint}) {
      margin: 20px auto;
      font-size: 20px;
    }
  }

  .firebaseui-container {
    max-width: unset;
  }
  .firebaseui-tos {
    margin-bottom: 0;
    color: ${colors.orange};
  }

  .firebaseui-tos-list {
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .firebaseui-link {
    color: ${colors.orange};
    text-decoration: underline;
  }

  .firebaseui-callback-indicator-container {
    height: ${mobileVW(60)};
    @media (min-width: ${desktopBreakpoint}) {
      height: unset;
    }
  }
  .mdl-card {
    min-height: unset;
  }

  .firebaseui-provider-sign-in-footer > .firebaseui-tos {
    text-align: left;
  }
  .firebaseui-card-footer {
    display: none;
  }
  .firebaseui-provider-sign-in-footer {
    display: none;
  }

  .firebaseui-input-floating-button {
    @media (min-width: ${desktopBreakpoint}) {
      top: 20px;
      height: 24px;
      width: 30px;
    }
  }
`

const Firebase = styled.div`
  position: relative;
`

const Title = styled.div`
  font-size: ${mobileVW(25)};
  line-height: ${mobileVW(40)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: lowercase;
  font-family: ${font.ben};
  margin: 0 0 ${mobileVW(40)};
  text-align: left;
  color: ${colors.brownDarkest};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 38px;
    line-height: 48px;
    margin: 0 0 80px;
  }

  b {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-weight: normal;
  }
`

const Authentication = styled.div`
  padding: ${mobileVW(41)} ${mobileVW(36)} ${mobileVW(52)} ${mobileVW(26)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 41px 60px 52px 48px;
  }
`

const BenefitsWrapper = styled.div`
  background-color: ${colors.beigeUltraLight};
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  text-align: center;

  h2 {
    text-transform: lowercase;
    font-size: ${mobileVW(24)};
    font-family: ${font.ben};
    padding: ${mobileVW(22)} ${mobileVW(90)};
    font-weight: normal;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 24px;
      font-family: ${font.ben};
      padding: 22px 90px;
    }
  }

  h3 {
    text-transform: none;
    padding: 0 ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      padding: 0 16px;
    }
  }
`

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 32px;
  }
`
const BenefitTitle = styled.h3`
  font-size: ${mobileVW(18)};
  margin-top: ${mobileVW(12)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
    margin-top: 12px;
  }
`
const StyledImage = styled(Image)`
  border-radius: 50%;
  width: ${mobileVW(120)};
  height: ${mobileVW(120)};
  @media (min-width: ${desktopBreakpoint}) {
    width: 120px;
    height: 120px;
  }
`

const AccountAuth = () => {
  const { node_locale } = useLayoutCtx()

  const data = useStaticQuery(graphql`
    {
      allContentfulAuth {
        edges {
          node {
            title {
              json
            }
            swiper {
              id
              name
              title
              backgroundColors
              slideCategories {
                title
                link {
                  ... on ContentfulCollectionPage {
                    slug
                  }
                  ... on ContentfulModularPage {
                    slug
                  }
                  ... on ContentfulTextPage {
                    slug
                  }
                }
              }
              customTitles
              icons {
                title
                fluid(maxWidth: 800) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              sectionBackgroundColor
              fullBackgroundImage
            }
          }
        }
      }
      allContentfulAccountPage {
        edges {
          node {
            slug
          }
        }
      }
    }
  `)

  const slug = data.allContentfulAccountPage.edges[0].node.slug
  const title = data.allContentfulAuth.edges[0].node.title.json
  const benefits = data.allContentfulAuth.edges[0].node.swiper

  const uiConfig = {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccess: () => {
        const referrer = sessionStorage.getItem('CYCLE_LOGIN_REF')
        const route = referrer.length > 1 ? referrer : `/${node_locale}/${slug}`

        navigate(route)
      },
    },
    tosUrl: '/nl/gebruikersvoorwaarden',
    privacyPolicyUrl: '/nl/privacy-statement-en-cookies',
  }

  return (
    <>
      <Wrapper className='AuthWrapper'>
        <Authentication>
          <Title>
            <RichText json={title} />
          </Title>
          <Firebase>
            <Auth uiConfig={uiConfig} />
          </Firebase>
        </Authentication>
        <BenefitsWrapper>
          <h2>{benefits.title}</h2>
          {benefits.slideCategories.map((category, i) => (
            <Benefit key={i}>
              <StyledImage
                fluid={benefits.icons[i].fluid}
                alt={benefits.icons[i].title}
              />
              <BenefitTitle>{benefits.customTitles[i]}</BenefitTitle>
            </Benefit>
          ))}
        </BenefitsWrapper>
      </Wrapper>
    </>
  )
}

export default AccountAuth
