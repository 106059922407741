import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { colors, font, desktopBreakpoint, mobileVW } from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'
import useClickOutside from '../../hooks/useClickOutside'

// Components
import Arrow from '../graphics/ArrowFlat'

const Container = styled.div`
  font-family: ${font.larsseit};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: black;
  position: relative;
  user-select: none;
  width: 54px;
  display: flex;
  justify-content: center;

  @media (min-width: ${desktopBreakpoint}) {
    display: ${({ desktop }) => (desktop ? 'flex' : 'none')};
  }

  @media (max-width: ${desktopBreakpoint}) {
    display: ${({ desktop }) => (desktop ? 'none' : 'flex')};
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  height: 14px;

  span {
    margin-right: 6px;
  }

  svg {
    transform: scale(${({ open }) => (open ? -1 : 1)});
    width: 11px;
  }

  &:hover {
    cursor: pointer;
  }
`

const Dropdown = styled.div`
  position: absolute;
  padding: 20px;
  background: #eee3da;
  top: calc(100% + 9px);
  right: 0;
  z-index: 4;
`

const List = styled.ul`
  list-style: none;
`

const Item = styled.li`
  height: 14px;
  line-height: 14px;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  &:checked + label {
    color: ${colors.beigeLight};
  }

  &:not(:checked) + label:hover {
    cursor: pointer;
  }
`

const Label = styled.label`
  color: black;
`

// Mobile
const Select = styled.select`
  appearance: none;
  background: none;
  border: none;
  font-size: ${mobileVW(18)};
  font-family: inherit;
  color: ${colors.beigeLight};
`

const LangSwitch = ({ desktop = false, onChange = null }) => {
  const { slug, locales, node_locale } = useLayoutCtx()

  const [isOpen, setIsOpen] = useState(false)

  // Close on locale change
  useEffect(() => {
    setIsOpen(false)

    if (typeof onChange === 'function') onChange()
  }, [node_locale])

  const clickOutsideRef = useClickOutside(() => {
    if (isOpen) setIsOpen(false)
  })

  const handleChange = e => {
    const path = getPathname(e.target.value, slug)

    navigate(path)
  }

  return (
    <Container desktop={desktop}>
      {desktop ? (
        <>
          <Button onClick={() => setIsOpen(!isOpen)} open={isOpen}>
            <span>{node_locale}</span>
            <Arrow color={'#000'} />
          </Button>
          {isOpen && (
            <Dropdown ref={clickOutsideRef}>
              <List>
                {locales.map(locale => (
                  <Item key={`li_${locale}`}>
                    <Input
                      onChange={handleChange}
                      type='radio'
                      value={locale}
                      name='locale'
                      id={`locale_${locale}`}
                      checked={locale === node_locale}
                    />
                    <Label htmlFor={`locale_${locale}`}>{locale}</Label>
                  </Item>
                ))}
              </List>
            </Dropdown>
          )}
        </>
      ) : (
        <Select
          defaultValue={node_locale}
          name='locale'
          onChange={handleChange}
        >
          {locales.map(locale => (
            <option key={`option_${locale}`} value={locale}>
              {locale.toUpperCase()}
            </option>
          ))}
        </Select>
      )}
    </Container>
  )
}

export default LangSwitch
