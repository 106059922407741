import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { useSelector } from 'react-redux'
import MarbyWoff from '../../fonts/mabry-medium-pro.woff'
import MarbyWoff2 from '../../fonts/mabry-medium-pro.woff2'
import MarbyLightWoff from '../../fonts/mabry-light-pro.woff'
import MarbyLightWoff2 from '../../fonts/mabry-light-pro.woff2'
import LarsseitWoff from '../../fonts/larsseit-regular.woff'
import LarsseitWoff2 from '../../fonts/larsseit-regular.woff2'
import LifeWoff from '../../fonts/life-regular.woff'
import LifeWoff2 from '../../fonts/life-regular.woff2'
import { colors, mobileVW, newDesktopVW, desktopBreakpoint } from '../../styles'
import BenjaminWoff from '../../fonts/benjamins-gothic-demi-bold.woff'
import BenjaminWoff2 from '../../fonts/benjamins-gothic-demi-bold.woff2'
// import MarbyRegWoff from '../fonts/mabry-light-pro.woff'
// import MarbyRegWoff2 from '../fonts/mabry-light-pro.woff2'
import NibWoff from '../../fonts/nib-italic-pro.woff'
import NibWoff2 from '../../fonts/nib-italic-pro.woff2'
import TrioWoff from '../../fonts/triogrotesk-medium-v102.woff'
import TrioWoff2 from '../../fonts/triogrotesk-medium-v102.woff2'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import Footer from './Footer'
import Header from './Header'
import Cart from './../cart/Cart'
import Search from '../search/Search'

const GlobalStyle = createGlobalStyle`

  html {
    scroll-behavior: smooth;
    scroll-padding: ${mobileVW(130)} 0 0 0;
    @media (min-width: ${desktopBreakpoint}) {
      scroll-padding: 60px 0 0 0;
    }
  }
  
  @font-face {
    font-family: 'Marby';
    src:
      url('${MarbyWoff2}') format('woff2'),
      url('${MarbyWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Marby';
    src:
      url('${MarbyLightWoff2}') format('woff2'),
      url('${MarbyLightWoff}') format('woff');
    font-weight: 300;
    font-style: light;
  }
  @font-face {
    font-family: 'Nib';
    src:
      url('${NibWoff2}') format('woff2'),
      url('${NibWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Trio';
    src:
      url('${TrioWoff2}') format('woff2'),
      url('${TrioWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Benjamin';
    src:
      url('${BenjaminWoff2}') format('woff2'),
      url('${BenjaminWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Larsseit';
    src:
      url('${LarsseitWoff2}') format('woff2'),
      url('${LarsseitWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Life';
    src:
      url('${LifeWoff2}') format('woff2'),
      url('${LifeWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:  'Marby', sans-serif;
    font-display: auto;
    overflow: ${props => (props.blockScroll ? 'hidden' : 'auto')};
  }
  h1, h2 ,h3 {
    font-size: inherit;
    margin: 0;
    font-weight: inherit;
  }
  p {
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    padding: 0;
    outline: none;
    background: none;
    cursor: pointer;
    color: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    line-height:inherit;
  }

  //swiper navigation style
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    top: 40%;
    color: ${colors.brownDarkest};
    width: ${mobileVW(45)};
    height: ${mobileVW(45)};
    background: ${colors.sand};
    opacity: 0.8;
    border-radius: 50%;

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml, %3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.625 1.25L9.66789 9.29289C10.0584 9.68342 10.0584 10.3166 9.66789 10.7071L1.625 18.75' stroke='%23070606' stroke-width='2'/%3E%3C/svg%3E");
      font-size: ${mobileVW(20)};
      font-weight: 700;
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 20px;
      }
    }
    @media (min-width: ${desktopBreakpoint}) {
      top: 50%;
      width: 64px;
      height: 64px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .swiper-button-disabled {
    visibility: hidden;
  }

  .swiper-button-prev {
    left: ${mobileVW(10)};
    right: auto;
    &::after {
      transform: rotate(180deg);
    }
    @media (min-width: ${desktopBreakpoint}) {
      left: 20px;
    }
  }
  .swiper-button-next {
    right: ${mobileVW(10)};
    left: auto;
    @media (min-width: ${desktopBreakpoint}) {
      right: 20px;
    }
  }

  .swiper-slide {
    border-radius: ${mobileVW(6)};
    overflow: hidden;
    @media (min-width: ${desktopBreakpoint}) {
      border-radius: 6px;
    }
  }

  // main {
  //   @media (min-width: ${desktopBreakpoint}) {
  //     max-width: 1280px;
  //     margin: auto;
  //   }
  // }
`

const Layout = ({ children }) => {
  const { hideFooter, rememberLocationRef } = useLayoutCtx()
  const searchOpen = useSelector(({ searchOpen }) => searchOpen)

  return (
    <div>
      <Normalize />
      <GlobalStyle blockScroll={searchOpen} />
      <Header />
      <Cart />
      <Search />
      <main>{children}</main>
      <Footer hidden={hideFooter} />
    </div>
  )
}

export default Layout
