import React from 'react'

const SearchIcon = props => (
  <svg
    width='27'
    height='27'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.2223 17.4445C14.6587 17.4445 17.4445 14.6587 17.4445 11.2223C17.4445 7.7858 14.6587 5 11.2223 5C7.7858 5 5 7.7858 5 11.2223C5 14.6587 7.7858 17.4445 11.2223 17.4445Z'
      stroke='#18100E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.8277 19L15.4443 15.6167'
      stroke='#18100E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SearchIcon
