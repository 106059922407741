import gql from 'graphql-tag'

export const product = gql`
  fragment ShopifyProduct on Product {
    handle
  }
`

export const variant = gql`
  fragment Variant on ProductVariant {
    availableForSale
    id
    product {
      ...ShopifyProduct
    }
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    image {
      transformedSrc
    }
    selectedOptions {
      name
      value
    }
    sku
    weight
    weightUnit
  }
  ${product}
`

export const checkout = gql`
  fragment ShopifyCheckout on Checkout {
    id
    lineItems(first: 250) {
      edges {
        node {
          title
          customAttributes {
            key
            value
          }
          quantity
          variant {
            ...Variant
          }
        }
      }
    }
    orderStatusUrl
    webUrl
    customAttributes {
      key
      value
    }
  }
  ${variant}
`
