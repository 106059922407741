import React from 'react'
import styled from 'styled-components'
import { setDrawerOpen } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

// Utils
import { getPathname } from '../../utils'

// Hooks
import useIsMobile from '../../hooks/useIsMobile'
import useAuth from '../../hooks/useAuth'
import { useDictionaryCtx, useLayoutCtx } from '../../hooks/context'

// Components
import { Link } from 'gatsby'
import AccountIcon from '../graphics/AccountIcon'
import AccountDrawer from '../account/AccountDrawer'

const Button = styled.button``

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: ${props => (props.visibility ? `block` : 'none')};
`

const AccountLink = ({ icon }) => {
  const { ready, user } = useAuth()
  const { account } = useDictionaryCtx()
  const { node_locale, accountPage, authenticationPage } = useLayoutCtx()

  const dispatch = useDispatch()
  const drawerOpen = useSelector(({ drawerOpen }) => drawerOpen)

  const handleClick = () => {
    dispatch(setDrawerOpen(false))
  }

  const isMobile = useIsMobile()

  return (
    <>
      {ready && user ? (
        <Link to={getPathname(node_locale, accountPage.slug)}>
          <span>{account}</span>
          {icon && <AccountIcon />}
        </Link>
      ) : isMobile ? (
        <Link to={getPathname(node_locale, authenticationPage.slug)}>
          <span>{account}</span>
          {icon && <AccountIcon />}
        </Link>
      ) : (
        <div>
          <Button onClick={() => dispatch(setDrawerOpen(!drawerOpen))}>
            <span>{account}</span>
            {icon && <AccountIcon />}
          </Button>
          <AccountDrawer
            visibility={drawerOpen ? 'true' : undefined}
            handleClick={handleClick}
          />
          <Overlay
            visibility={drawerOpen ? 'true' : undefined}
            onClick={() => dispatch(setDrawerOpen(!drawerOpen))}
          />
        </div>
      )}
    </>
  )
}

export default AccountLink
