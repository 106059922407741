import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import NewsletterEmail from './NewsletterEmail'
import ColorBanner from './colorBanner'

// Helpers
function getFields(fields, locale, fallbackLocale) {
  const returnObject = {}

  for (const key in fields) {
    returnObject[key] = fields[key][locale] || fields[key][fallbackLocale]
  }

  return returnObject
}

function getOptions(locale, fallbackLocale) {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target='_blank' rel='noopener noreferrer'>
            {children}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => {
        const { file, title } = getFields(fields, locale, fallbackLocale)

        return <img alt={title} src={file?.url} />
      },
      [BLOCKS.EMBEDDED_ENTRY]: obj => {
        const contentful_id =
          obj?.data?.target?.sys?.contentType?.sys?.contentful_id
        const fields = obj?.data?.target?.fields

        if (!contentful_id || !fields) return null

        switch (contentful_id) {
          case 'colorBanner': {
            const props = getFields(fields, locale, fallbackLocale)

            return <ColorBanner {...props} />
          }
          case 'componentEmailCapture': {
            const props = getFields(fields, locale, fallbackLocale)

            return (
              <NewsletterEmail
                className='embedded-newsletter-block'
                {...props}
              />
            )
          }
          default:
            return null
        }
      },
    },
  }

  return options
}

const RichText = ({ json }) => {
  const { node_locale, default_locale } = useLayoutCtx()
  const options = getOptions(node_locale, default_locale)

  return documentToReactComponents(json, options)
}

export default RichText
