import { useState, useEffect } from 'react'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    typeof window != 'undefined' && window.innerWidth <= 1024 ? true : false,
  )
  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const handleResize = () => {
      window.innerWidth <= 1024 ? setIsMobile(true) : setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}

export default useIsMobile
