import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { mobileVW, desktopBreakpoint } from '../../../styles'

// Icons
import CheckMark from '../../graphics/CheckmarkIcon'

// Styles
const Container = styled.div`
  width: ${mobileVW(19)};
  min-width: ${mobileVW(19)};
  max-width: ${mobileVW(19)};
  height: ${mobileVW(20)};
  border: solid ${mobileVW(2)} ${({ color }) => color};
  position: relative;
  border-radius: ${mobileVW(4)};

  @media (min-width: ${desktopBreakpoint}) {
    width: 19px;
    min-width: 19px;
    max-width: 19px;
    height: 20px;
    border-width: 2px;
    border-radius: 4px;
  }

  svg {
    pointer-events: none;
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      transition: all 0.3s ease;
    }
  }
`

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }

  &:checked + svg path {
    stroke-dashoffset: 0;
  }
`

const Checkbox = ({
  onChange: _onChange = null,
  checked: _checked = false,
  color = 'white',
}) => {
  const [checked, setChecked] = useState(_checked)

  useEffect(() => {
    if (!_onChange) return

    _onChange(checked)
  }, [checked])

  return (
    <Container color={color}>
      <Input type='checkbox' onChange={e => setChecked(e.target.checked)} />
      <CheckMark color={color} />
    </Container>
  )
}

export default Checkbox
