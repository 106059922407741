import React from 'react'

const CheckMark = ({ color = 'black' }) => {
  return (
    <svg
      width='38'
      height='28'
      viewBox='0 0 38 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 15.2222L12.8462 25L35 3'
        stroke={color}
        strokeWidth='6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CheckMark
