import React from 'react'
import styled from 'styled-components'
import { font, colors, desktopBreakpoint } from '../../styles'

// Components
import AccountAuth from './AccountAuth'
import CloseIcon from '../graphics/Close'

const Drawer = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    z-index: 10;
    height: 100vh;
    width: 420px;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: scroll;
    transition: transform 0.5s ease-in-out;
    transform: ${props =>
      props.visibility ? `translateX(0%)` : 'translateX(100%)'};

    .AuthWrapper {
      padding: 41px 0 0;
      height: calc(100vh - 80px);
      .circleSwiper {
        display: block;
      }
    }
  }
`

const Heading = styled.div`
  min-height: 80px;
  width: 100%;
  background-color: ${colors.sand};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.h3`
  font-size: 20px;
  line-height: 19px;
  font-family: ${font.ben};
  text-transform: lowercase;
  color: ${colors.brownDarkest};
  font-weight: normal;
`

const Button = styled.button`
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`

const AccountDrawer = ({ visibility, handleClick }) => {
  return (
    <Drawer visibility={visibility ? 'true' : undefined}>
      <Heading>
        <Button onClick={() => handleClick()}>
          <CloseIcon color='#18100E' />
        </Button>
        <Title>sign in</Title>
      </Heading>
      <AccountAuth />
    </Drawer>
  )
}

export default AccountDrawer
