import React from 'react'

const Close = ({ color = '#ff7002' }) => (
  <svg viewBox='0 0 11.697 11.697'>
    <g
      id='Group_513'
      data-name='Group 513'
      transform='translate(-36.292 -23.937)'
    >
      <path
        id='Path_319'
        data-name='Path 319'
        d='M0,5.008H15.542'
        transform='translate(40.186 20.749) rotate(45)'
        fill='none'
        stroke={color}
        style={{ stroke: color }}
        strokeWidth='1'
      />
      <path
        id='Path_321'
        data-name='Path 321'
        d='M0,5.008H15.542'
        transform='translate(33.105 31.74) rotate(-45)'
        fill='none'
        stroke={color}
        strokeWidth='1'
        style={{ stroke: color }}
      />
    </g>
  </svg>
)
export default Close
