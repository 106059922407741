module.exports = {
  getLocalStorageCheckoutKey: store => `checkouts::${store}`,
  getPathname: (locale, slug) => `/${locale}${slug ? `/${slug}` : ''}`,
  validateEmail: email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(email).toLowerCase())
  },
  debounce: (cb, delay) => {
    let timeout = null

    return (...args) => {
      clearTimeout(timeout)

      timeout = setTimeout(() => cb(...args), delay)
    }
  },
  getTopOffset: element => {
    const { top } = element.getBoundingClientRect()

    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop

    const clientTop =
      document.documentElement.clientTop || document.body.clientTop || 0

    return Math.round(top + scrollTop - clientTop)
  },
}
