import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import { setAuthReady } from '../actions'

const useAuth = () => {
  const ready = useSelector(({ authReady }) => authReady)
  const dispatch = useDispatch()
  const [user, setUser] = useState(ready ? firebase.auth().currentUser : null)

  useEffect(() => {
    const unregister = firebase.auth().onAuthStateChanged(u => {
      setUser(u)
      dispatch(setAuthReady(true))
    })

    return unregister
  }, [dispatch])

  return {
    ready,
    user,
    signOut: () => firebase.auth().signOut(),
  }
}

export default useAuth
