import React from 'react'

const Bag = props => (
  <svg
    width='27'
    height='27'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.5 9.5V9C16.5 6.51472 14.4853 4.5 12 4.5V4.5C9.51472 4.5 7.5 6.51472 7.5 9V9.5'
      stroke='#18100E'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M19.1941 11.9054C18.749 15.4818 15.6972 18.25 12 18.25C8.30279 18.25 5.25097 15.4818 4.80591 11.9054C4.80173 11.8718 4.80988 11.8433 4.8387 11.8134C4.87144 11.7793 4.92793 11.75 5 11.75H19C19.0721 11.75 19.1286 11.7793 19.1613 11.8134C19.1901 11.8433 19.1983 11.8718 19.1941 11.9054Z'
      stroke='#18100E'
      strokeWidth='1.5'
    />
  </svg>
)

export default Bag
