import React from 'react'

const Facebook = props => (
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.10345 0H18.8966C19.1892 0 19.4699 0.116256 19.6768 0.323193C19.8837 0.530129 20 0.810796 20 1.10345V18.8966C20 19.1892 19.8837 19.4699 19.6768 19.6768C19.4699 19.8837 19.1892 20 18.8966 20H13.7931V12.2655H16.4032L16.4036 12.2621H16.4207L16.8103 9.23448H13.8103V7.3069C13.8103 6.57628 13.9807 6.04242 14.7658 5.88545C14.9178 5.85647 15.0924 5.84135 15.2928 5.84135H16.8963V5.83793H16.8966V3.13793C16.8965 3.13792 16.8964 3.13791 16.8963 3.1379C16.7999 3.12794 16.7034 3.11861 16.6068 3.10991C16.4117 3.09233 16.2163 3.07735 16.0208 3.06496C15.5375 3.03432 15.0532 3.01953 14.5687 3.02066C12.2618 3.02066 10.6894 4.42756 10.6894 7.01032V9.2379H8.08594V12.2655H8.08621V12.2655H10.6894V20H10.6897V20H1.10345C0.810796 20 0.530129 19.8837 0.323193 19.6768C0.116256 19.4699 0 19.1892 0 18.8966V1.10345C0 0.810796 0.116256 0.530129 0.323193 0.323193C0.530129 0.116256 0.810796 0 1.10345 0Z'
      fill='#EEE3DA'
    />
  </svg>
)

export default Facebook
