import React from 'react'

const Logo = props => (
  <svg
    width='105'
    height='38'
    viewBox='0 0 105 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.44677 29.5908C7.61318 29.5908 5.97874 29.1951 4.54346 28.4036C3.10818 27.612 1.99291 26.5093 1.19492 25.0939C0.398308 23.6785 0 22.0499 0 20.2066C0 17.9487 0.618065 15.8719 1.85419 13.9763C3.09032 12.082 4.77008 10.5835 6.89623 9.47935C9.02237 8.37658 11.36 7.8252 13.9119 7.8252C15.6659 7.8252 17.233 8.10424 18.6161 8.66234C19.9978 9.22043 21.0733 10.0052 21.8451 11.0181C22.6157 12.031 23.0016 13.1727 23.0016 14.4445C23.0016 15.7941 22.6033 16.9036 21.8053 17.7729C21.0087 18.6436 19.9841 19.077 18.7356 19.077C17.6986 19.077 16.8484 18.7791 16.185 18.1808C15.5532 17.6133 15.2222 16.9063 15.1907 16.0571C15.1783 15.7177 15.2731 15.173 15.3788 14.8497C15.4571 14.6109 15.5395 14.3841 15.6274 14.1708C15.92 13.5483 16.0656 13.0157 16.0656 12.5744C16.0656 11.9519 15.8595 11.4515 15.4475 11.0758C15.0354 10.7002 14.497 10.511 13.8323 10.511C12.9285 10.511 12.0852 10.8558 11.301 11.5427C10.5167 12.2309 9.89865 13.1579 9.44677 14.3264C8.9949 15.495 8.76965 16.7668 8.76965 18.1419C8.76965 20.1931 9.28058 21.8607 10.3038 23.1446C11.3271 24.4298 12.636 25.0724 14.2306 25.0724C15.0808 25.0724 16.0051 24.8779 17.0009 24.4889C17.8154 24.1709 18.5351 23.7832 19.1641 23.3271C19.4059 23.1513 19.7424 23.1741 19.9552 23.3834C20.1571 23.5806 20.1915 23.8878 20.039 24.1239C19.057 25.652 17.6327 26.9225 15.7661 27.9353C13.732 29.0395 11.6265 29.5908 9.44677 29.5908Z'
      fill='#DE742C'
    />
    <path
      d='M26.1414 37.9999C24.2543 37.9999 22.7736 37.6364 21.6968 36.9092C20.62 36.1821 20.083 35.1826 20.083 33.9108C20.083 32.8724 20.4154 32.0286 21.0788 31.3806C21.7422 30.7313 22.5937 30.4066 23.6293 30.4066C24.8517 30.4066 26.1002 30.8869 27.3762 31.8475C28.4653 32.6779 29.5545 33.0938 30.645 33.0938C31.8413 33.0938 33.1819 32.6135 34.6707 31.653C35.7448 30.9594 36.7707 30.09 37.7487 29.0449C38.0069 28.7686 37.7006 28.346 37.3476 28.4922C35.5854 29.2234 33.6969 29.5883 31.6806 29.5883C29.0504 29.5883 27.0108 28.9269 25.5618 27.6028C24.1128 26.2786 23.389 24.4232 23.389 22.0352C23.389 19.2314 24.3586 16.1699 26.2994 12.8468L26.379 12.6912H25.8612C24.7611 12.6912 23.8683 11.8192 23.8683 10.7446C23.8683 9.66998 24.7611 8.79796 25.8612 8.79796H37.0853C37.3586 8.79796 37.5289 9.08639 37.3916 9.31714L35.786 12.0298C34.4303 14.3145 33.4538 16.2289 32.8563 17.7731C32.2589 19.3172 31.9595 20.7004 31.9595 21.9199C31.9595 23.2695 32.2918 24.3213 32.9552 25.0739C33.6186 25.8265 34.5361 26.2035 35.7063 26.2035C36.8751 26.2035 37.9986 25.8466 39.0741 25.1329C40.1509 24.4192 41.0134 23.4721 41.6644 22.2901C42.3155 21.1096 42.641 19.8699 42.641 18.5713C42.641 17.6899 42.4748 16.9628 42.1424 16.3912C41.81 15.8211 41.2593 15.314 40.4887 14.8726C38.8406 13.9375 38.0179 12.6925 38.0179 11.135C38.0179 10.1221 38.3434 9.31849 38.9944 8.72149C39.6454 8.12449 40.5148 7.82666 41.6054 7.82666C43.0929 7.82666 44.2301 8.44915 45.013 9.69547C45.7959 10.9418 46.1887 12.7462 46.1887 15.1074C46.1887 18.9496 45.2382 22.6349 43.3387 26.1646C41.4378 29.6943 38.934 32.5505 35.8244 34.7305C32.7176 36.9106 29.4899 37.9999 26.1414 37.9999Z'
      fill='#DE742C'
    />
    <path
      d='M56.7147 29.591C54.8811 29.591 53.2467 29.1952 51.8114 28.4037C50.3762 27.6122 49.2609 26.5094 48.4629 25.094C47.6663 23.68 47.2666 22.0513 47.2666 20.208C47.2666 17.9502 47.8847 15.8734 49.1208 13.9778C50.3569 12.0835 52.0367 10.5849 54.1628 9.48082C56.289 8.37805 58.6266 7.82666 61.1785 7.82666C62.9325 7.82666 64.4996 8.10571 65.8827 8.6638C67.2644 9.22189 68.3398 10.0067 69.1117 11.0196C69.8823 12.0325 70.2682 13.1742 70.2682 14.446C70.2682 15.7956 69.8699 16.9051 69.0719 17.7744C68.2753 18.6451 67.2507 19.0784 66.0022 19.0784C64.9652 19.0784 64.115 18.7806 63.4516 18.1822C62.8198 17.6148 62.4888 16.9078 62.4572 16.0585C62.4449 15.7191 62.5396 15.1744 62.6454 14.8511C62.7237 14.6123 62.8061 14.3856 62.894 14.1723C63.1866 13.5498 63.3321 13.0172 63.3321 12.5758C63.3321 11.9533 63.1261 11.4529 62.7141 11.0773C62.302 10.7016 61.7636 10.5125 61.0989 10.5125C60.1951 10.5125 59.3518 10.8573 58.5676 11.5442C57.7833 12.2324 57.1652 13.1594 56.7134 14.3279C56.2615 15.4964 56.0362 16.7682 56.0362 18.1433C56.0362 20.1946 56.5472 21.8622 57.5704 23.1461C58.5937 24.4313 59.9026 25.0739 61.4972 25.0739C62.3474 25.0739 63.2717 24.8794 64.2675 24.4903C65.152 24.1442 65.9266 23.7176 66.5914 23.2091C66.7342 23.0991 66.9361 23.1072 67.0653 23.2319L67.3633 23.523C67.4773 23.6344 67.502 23.8088 67.4196 23.943C66.4348 25.5515 64.9734 26.8837 63.0327 27.9355C60.9986 29.0396 58.8931 29.591 56.7147 29.591Z'
      fill='#DE742C'
    />
    <path
      d='M75.0114 29.591C73.311 29.591 71.9952 29.1631 71.0654 28.3058C70.1355 27.4499 69.6699 26.2291 69.6699 24.646C69.6699 23.8411 69.8155 22.9597 70.1081 21.9977L74.6021 5.48166C74.7339 4.99601 74.6254 4.47817 74.3081 4.08374L73.1036 2.58521C72.2603 1.53744 73.0253 0 74.3906 0H83.9156C84.1491 0 84.318 0.215993 84.2576 0.436011L78.0797 22.9717C77.9465 23.5432 77.8806 23.9833 77.8806 24.2959C77.8806 24.8405 77.9932 25.2564 78.2198 25.5422C78.445 25.8279 78.7706 25.9701 79.1963 25.9701C79.4779 25.9701 79.754 25.9111 80.0204 25.7904C80.1729 25.7219 80.3528 25.7609 80.4544 25.8923L80.7717 26.3002C80.8555 26.4075 80.8706 26.5551 80.8033 26.6731C80.2992 27.5653 79.5631 28.2656 78.5975 28.774C77.5619 29.3187 76.3656 29.591 75.0114 29.591Z'
      fill='#DE742C'
    />
    <path
      d='M91.3376 29.591C88.2542 29.591 85.8231 28.7807 84.0431 27.1574C82.2631 25.5354 81.373 23.3218 81.373 20.5193C81.373 18.1313 82.0309 15.9713 83.3467 14.0368C84.6625 12.1036 86.4824 10.5849 88.8077 9.48082C91.133 8.37805 93.7302 7.82666 96.6008 7.82666C99.2049 7.82666 101.212 8.30694 102.619 9.26751C104.027 10.2281 104.732 11.5911 104.732 13.3553C104.732 15.64 103.542 17.3397 101.165 18.4559C98.8519 19.5413 95.3509 20.0994 90.6632 20.1289C90.4462 20.1302 90.2814 20.3194 90.313 20.5287C90.5176 21.9199 91.0396 23.0065 91.8788 23.79C92.7949 24.6473 94.0379 25.0752 95.6064 25.0752C96.6159 25.0752 97.5856 24.8874 98.5168 24.5104C99.3779 24.163 100.319 23.5968 101.338 22.8147C101.479 22.706 101.683 22.7154 101.809 22.8402L102.113 23.1367C102.225 23.2467 102.25 23.4171 102.172 23.5526C101.045 25.4898 99.5881 26.9709 97.7984 27.9945C95.9346 29.0584 93.7824 29.591 91.3376 29.591ZM90.2663 17.5195C90.2388 17.7261 90.4105 17.9099 90.6234 17.9059C92.7372 17.8589 94.3496 17.439 95.4622 16.6448C96.6447 15.801 97.2367 14.5748 97.2367 12.9649C97.2367 11.3295 96.6392 10.5125 95.4429 10.5125C94.6463 10.5125 93.8689 10.8506 93.1108 11.5254C92.354 12.2002 91.7153 13.1017 91.1975 14.2313C90.7319 15.2482 90.4215 16.3443 90.2663 17.5195Z'
      fill='#DE742C'
    />
    <path
      d='M9.44677 29.5908C7.61318 29.5908 5.97874 29.1951 4.54346 28.4036C3.10818 27.612 1.99291 26.5093 1.19492 25.0939C0.398308 23.6785 0 22.0499 0 20.2066C0 17.9487 0.618065 15.8719 1.85419 13.9763C3.09032 12.082 4.77008 10.5835 6.89623 9.47935C9.02237 8.37658 11.36 7.8252 13.9119 7.8252C15.6659 7.8252 17.233 8.10424 18.6161 8.66234C19.9978 9.22043 21.0733 10.0052 21.8451 11.0181C22.6157 12.031 23.0016 13.1727 23.0016 14.4445C23.0016 15.7941 22.6033 16.9036 21.8053 17.7729C21.0087 18.6436 19.9841 19.077 18.7356 19.077C17.6986 19.077 16.8484 18.7791 16.185 18.1808C15.5532 17.6133 15.2222 16.9063 15.1907 16.0571C15.1783 15.7177 15.2731 15.173 15.3788 14.8497C15.4571 14.6109 15.5395 14.3841 15.6274 14.1708C15.92 13.5483 16.0656 13.0157 16.0656 12.5744C16.0656 11.9519 15.8595 11.4515 15.4475 11.0758C15.0354 10.7002 14.497 10.511 13.8323 10.511C12.9285 10.511 12.0852 10.8558 11.301 11.5427C10.5167 12.2309 9.89865 13.1579 9.44677 14.3264C8.9949 15.495 8.76965 16.7668 8.76965 18.1419C8.76965 20.1931 9.28058 21.8607 10.3038 23.1446C11.3271 24.4298 12.636 25.0724 14.2306 25.0724C15.0808 25.0724 16.0051 24.8779 17.0009 24.4889C17.8154 24.1709 18.5351 23.7832 19.1641 23.3271C19.4059 23.1513 19.7424 23.1741 19.9552 23.3834C20.1571 23.5806 20.1915 23.8878 20.039 24.1239C19.057 25.652 17.6327 26.9225 15.7661 27.9353C13.732 29.0395 11.6265 29.5908 9.44677 29.5908Z'
      fill='#DE742C'
    />
    <path
      d='M26.1414 37.9999C24.2543 37.9999 22.7736 37.6364 21.6968 36.9092C20.62 36.1821 20.083 35.1826 20.083 33.9108C20.083 32.8724 20.4154 32.0286 21.0788 31.3806C21.7422 30.7313 22.5937 30.4066 23.6293 30.4066C24.8517 30.4066 26.1002 30.8869 27.3762 31.8475C28.4653 32.6779 29.5545 33.0938 30.645 33.0938C31.8413 33.0938 33.1819 32.6135 34.6707 31.653C35.7448 30.9594 36.7707 30.09 37.7487 29.0449C38.0069 28.7686 37.7006 28.346 37.3476 28.4922C35.5854 29.2234 33.6969 29.5883 31.6806 29.5883C29.0504 29.5883 27.0108 28.9269 25.5618 27.6028C24.1128 26.2786 23.389 24.4232 23.389 22.0352C23.389 19.2314 24.3586 16.1699 26.2994 12.8468L26.379 12.6912H25.8612C24.7611 12.6912 23.8683 11.8192 23.8683 10.7446C23.8683 9.66998 24.7611 8.79796 25.8612 8.79796H37.0853C37.3586 8.79796 37.5289 9.08639 37.3916 9.31714L35.786 12.0298C34.4303 14.3145 33.4538 16.2289 32.8563 17.7731C32.2589 19.3172 31.9595 20.7004 31.9595 21.9199C31.9595 23.2695 32.2918 24.3213 32.9552 25.0739C33.6186 25.8265 34.5361 26.2035 35.7063 26.2035C36.8751 26.2035 37.9986 25.8466 39.0741 25.1329C40.1509 24.4192 41.0134 23.4721 41.6644 22.2901C42.3155 21.1096 42.641 19.8699 42.641 18.5713C42.641 17.6899 42.4748 16.9628 42.1424 16.3912C41.81 15.8211 41.2593 15.314 40.4887 14.8726C38.8406 13.9375 38.0179 12.6925 38.0179 11.135C38.0179 10.1221 38.3434 9.31849 38.9944 8.72149C39.6454 8.12449 40.5148 7.82666 41.6054 7.82666C43.0929 7.82666 44.2301 8.44915 45.013 9.69547C45.7959 10.9418 46.1887 12.7462 46.1887 15.1074C46.1887 18.9496 45.2382 22.6349 43.3387 26.1646C41.4378 29.6943 38.934 32.5505 35.8244 34.7305C32.7176 36.9106 29.4899 37.9999 26.1414 37.9999Z'
      fill='#DE742C'
    />
    <path
      d='M56.7147 29.591C54.8811 29.591 53.2467 29.1952 51.8114 28.4037C50.3762 27.6122 49.2609 26.5094 48.4629 25.094C47.6663 23.68 47.2666 22.0513 47.2666 20.208C47.2666 17.9502 47.8847 15.8734 49.1208 13.9778C50.3569 12.0835 52.0367 10.5849 54.1628 9.48082C56.289 8.37805 58.6266 7.82666 61.1785 7.82666C62.9325 7.82666 64.4996 8.10571 65.8827 8.6638C67.2644 9.22189 68.3398 10.0067 69.1117 11.0196C69.8823 12.0325 70.2682 13.1742 70.2682 14.446C70.2682 15.7956 69.8699 16.9051 69.0719 17.7744C68.2753 18.6451 67.2507 19.0784 66.0022 19.0784C64.9652 19.0784 64.115 18.7806 63.4516 18.1822C62.8198 17.6148 62.4888 16.9078 62.4572 16.0585C62.4449 15.7191 62.5396 15.1744 62.6454 14.8511C62.7237 14.6123 62.8061 14.3856 62.894 14.1723C63.1866 13.5498 63.3321 13.0172 63.3321 12.5758C63.3321 11.9533 63.1261 11.4529 62.7141 11.0773C62.302 10.7016 61.7636 10.5125 61.0989 10.5125C60.1951 10.5125 59.3518 10.8573 58.5676 11.5442C57.7833 12.2324 57.1652 13.1594 56.7134 14.3279C56.2615 15.4964 56.0362 16.7682 56.0362 18.1433C56.0362 20.1946 56.5472 21.8622 57.5704 23.1461C58.5937 24.4313 59.9026 25.0739 61.4972 25.0739C62.3474 25.0739 63.2717 24.8794 64.2675 24.4903C65.152 24.1442 65.9266 23.7176 66.5914 23.2091C66.7342 23.0991 66.9361 23.1072 67.0653 23.2319L67.3633 23.523C67.4773 23.6344 67.502 23.8088 67.4196 23.943C66.4348 25.5515 64.9734 26.8837 63.0327 27.9355C60.9986 29.0396 58.8931 29.591 56.7147 29.591Z'
      fill='#DE742C'
    />
    <path
      d='M75.0114 29.591C73.311 29.591 71.9952 29.1631 71.0654 28.3058C70.1355 27.4499 69.6699 26.2291 69.6699 24.646C69.6699 23.8411 69.8155 22.9597 70.1081 21.9977L74.6021 5.48166C74.7339 4.99601 74.6254 4.47817 74.3081 4.08374L73.1036 2.58521C72.2603 1.53744 73.0253 0 74.3906 0H83.9156C84.1491 0 84.318 0.215993 84.2576 0.436011L78.0797 22.9717C77.9465 23.5432 77.8806 23.9833 77.8806 24.2959C77.8806 24.8405 77.9932 25.2564 78.2198 25.5422C78.445 25.8279 78.7706 25.9701 79.1963 25.9701C79.4779 25.9701 79.754 25.9111 80.0204 25.7904C80.1729 25.7219 80.3528 25.7609 80.4544 25.8923L80.7717 26.3002C80.8555 26.4075 80.8706 26.5551 80.8033 26.6731C80.2992 27.5653 79.5631 28.2656 78.5975 28.774C77.5619 29.3187 76.3656 29.591 75.0114 29.591Z'
      fill='#DE742C'
    />
    <path
      d='M91.3376 29.591C88.2542 29.591 85.8231 28.7807 84.0431 27.1574C82.2631 25.5354 81.373 23.3218 81.373 20.5193C81.373 18.1313 82.0309 15.9713 83.3467 14.0368C84.6625 12.1036 86.4824 10.5849 88.8077 9.48082C91.133 8.37805 93.7302 7.82666 96.6008 7.82666C99.2049 7.82666 101.212 8.30694 102.619 9.26751C104.027 10.2281 104.732 11.5911 104.732 13.3553C104.732 15.64 103.542 17.3397 101.165 18.4559C98.8519 19.5413 95.3509 20.0994 90.6632 20.1289C90.4462 20.1302 90.2814 20.3194 90.313 20.5287C90.5176 21.9199 91.0396 23.0065 91.8788 23.79C92.7949 24.6473 94.0379 25.0752 95.6064 25.0752C96.6159 25.0752 97.5856 24.8874 98.5168 24.5104C99.3779 24.163 100.319 23.5968 101.338 22.8147C101.479 22.706 101.683 22.7154 101.809 22.8402L102.113 23.1367C102.225 23.2467 102.25 23.4171 102.172 23.5526C101.045 25.4898 99.5881 26.9709 97.7984 27.9945C95.9346 29.0584 93.7824 29.591 91.3376 29.591ZM90.2663 17.5195C90.2388 17.7261 90.4105 17.9099 90.6234 17.9059C92.7372 17.8589 94.3496 17.439 95.4622 16.6448C96.6447 15.801 97.2367 14.5748 97.2367 12.9649C97.2367 11.3295 96.6392 10.5125 95.4429 10.5125C94.6463 10.5125 93.8689 10.8506 93.1108 11.5254C92.354 12.2002 91.7153 13.1017 91.1975 14.2313C90.7319 15.2482 90.4215 16.3443 90.2663 17.5195Z'
      fill='#DE742C'
    />
  </svg>
)

export default Logo
