import React, { useState, useEffect } from 'react'
import useShopifyProduct from '../../hooks/useShopifyProduct'

const Price = ({ shopifyProduct }) => {
  const { product } = useShopifyProduct(shopifyProduct.handle)
  const [price, setPrice] = useState(null)

  useEffect(() => {
    if (product) {
      const dynamicPrice = product.variants.edges[0].node.price
      setPrice(dynamicPrice.amount)
    }
  }, [product])

  return <>{price && <span>€{Number(price).toFixed(2)}</span>}</>
}

export default Price
