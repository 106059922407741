// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-account-js": () => import("./../../../src/templates/Account.js" /* webpackChunkName: "component---src-templates-account-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/Articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-auth-js": () => import("./../../../src/templates/Auth.js" /* webpackChunkName: "component---src-templates-auth-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-expert-js": () => import("./../../../src/templates/Expert.js" /* webpackChunkName: "component---src-templates-expert-js" */),
  "component---src-templates-modular-js": () => import("./../../../src/templates/Modular.js" /* webpackChunkName: "component---src-templates-modular-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-text-js": () => import("./../../../src/templates/Text.js" /* webpackChunkName: "component---src-templates-text-js" */)
}

