import React, { useRef, useEffect } from 'react'

const useClickOutside = cb => {
  const ref = useRef()

  const handleClick = e => {
    if (
      !ref.current ||
      typeof cb !== 'function' ||
      ref.current.contains(e.target) ||
      ref.current === e.target
    )
      return

    cb()
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  })

  return ref
}

export default useClickOutside
