import { gql } from 'apollo-boost'
import { variant } from '../fragments/shopify'

const query = gql`
  query ShopifyProduct($handle: String!) {
    product: productByHandle(handle: $handle) {
      handle
      title
      variants(first: 250) {
        edges {
          node {
            ...Variant
          }
        }
      }
    }
  }
  ${variant}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/queryroot
 *
 * @param {{handle: string}} variables
 */
const queryProductByHandle = (client, variables) =>
  client.query({ query, variables })

export default queryProductByHandle
