import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setCartOpen } from '../../actions'
import { mobileVW, desktopBreakpoint } from '../../styles'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'
import useShopifyProduct from '../../hooks/useShopifyProduct'
import useReplaceLineItem from '../../hooks/useReplaceLineItem'
import useUpdateCheckoutAttributes from '../../hooks/useUpdateCheckoutAttributes'

// Components
import Bag from '../graphics/Bag'

const AddToCartButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: ${mobileVW(7)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 7px;
    }
  }

  svg {
    display: ${props => (props.loading ? 'none' : 'block')};
  }

  span {
    margin-left: ${mobileVW(7)};
    white-space: nowrap;
    @media (min-width: ${desktopBreakpoint}) {
      margin-left: 7px;
    }
    &:first-of-type {
      display: ${props => (props.loading ? 'none' : 'block')};
    }
    &:last-of-type {
      display: ${props => (props.loading ? 'block' : 'none')};
    }
  }
`

const AddToCart = ({ handle, upsell, title, location }) => {
  const dictionary = useDictionaryCtx()
  const dispatch = useDispatch()
  const { product } = useShopifyProduct(handle)
  const checkout = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )
  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )
  const [replaceLineItem, { loading }] = useReplaceLineItem()
  const [updateCheckoutAttributes] = useUpdateCheckoutAttributes()
  const [variantId, setVariantId] = useState(null)
  const [quantity, setQuantity] = useState(1)

  const lineItem = lineItems.edges.find(
    ({
      node: {
        variant: { id },
      },
    }) => id === variantId,
  )

  useEffect(() => {
    if (product) {
      setVariantId(product.variants.edges[0].node.id)
    }
  }, [product])
  useEffect(() => {
    if (lineItem) {
      setQuantity(lineItem.node.quantity + 1)
    } else {
      setQuantity(1)
    }
  }, [lineItem])

  const AddProductToCart = async () => {
    if (upsell) {
      updateCheckoutAttributes({
        customAttributes: [
          { key: `upsell-${handle}`, value: upsell },
          ...checkout.customAttributes,
        ],
      })
    }
    await replaceLineItem({
      variantId,
      quantity,
    })
    dispatch(setCartOpen(true))

    if (
      variantId &&
      typeof window !== 'undefined' &&
      window.dataLayer &&
      location
    ) {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                slug: location.pathname,
                name: title,
                price: product.variants.edges[0].node.price.amount,
                quantity: 1,
                currency: 'EUR',
                brand: 'CYCLE',
                category: 'Product',
              },
            ],
          },
        },
      })
    }
  }

  return (
    <AddToCartButton
      type='button'
      aria-label='Add to cart'
      loading={loading ? 'true' : undefined}
      onClick={AddProductToCart}
    >
      <Bag />
      <div>
        <span className='add'>{dictionary.addToCart}</span>
        <span className='loading'>{dictionary.addToCartLoading}</span>
      </div>
    </AddToCartButton>
  )
}

export default AddToCart
