import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setSearchOpen } from '../../actions'

// Utils
import { getPathname } from '/src/utils'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Price from '../shared/Price_Algolia'
import ArticleLabel from '../../components/shared/uiComponents/articleLabel'

const Wrapper = styled(Link)`
  display: flex;
  box-shadow: 0px 0px 6.75px rgba(101, 71, 63, 0.1);
  margin: ${mobileVW(4)};
  padding: ${mobileVW(8)} ${mobileVW(11)};
  border-radius: ${mobileVW(6)};
  overflow-y: scroll;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: column;
    width: 188px;
    height: 247px;
    margin: 0 4px 20px;
    padding: 14px 12px;
    border-radius: 6px;
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 3px;
`

const StyledImage = styled(Image)`
  width: ${mobileVW(106)};
  height: ${mobileVW(106)};

  @media (min-width: ${desktopBreakpoint}) {
    width: 164px;
    height: 130px;
  }
`

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: ${mobileVW(13)} ${mobileVW(22)};
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    height: 100%;
  }
`

const LabelWrapper = styled.div`
  position: absolute;
  bottom: 0;
`

const Title = styled.h3`
  text-transform: ${props => props.text};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: ${font.ben};
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    -webkit-line-clamp: 2;
  }
`

const Type = styled.p`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${mobileVW(13)};
  margin-top: ${mobileVW(5)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 13px;
    margin-top: 3px;
  }
`

const PriceWrapper = styled.div`
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(13)};
  margin-top: ${mobileVW(5)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 13px;
    margin-top: 7px;
  }
`

import { mobileVW, font, desktopBreakpoint, colors } from '../../styles'

const ResultCard = ({ hit }) => {
  const { node_locale } = useLayoutCtx()

  const {
    title,
    slug,
    videoLink,
    jobFunction,
    shopifyProduct,
    expert,
    productDescriptionShort,
    imageThumbnail,
    image,
    label,
  } = hit

  const dispatch = useDispatch()

  return (
    <>
      <Wrapper
        to={slug ? getPathname(node_locale, slug) : `${videoLink}`}
        target={videoLink ? '_blank' : null}
        onClick={() => dispatch(setSearchOpen(false))}
      >
        <ImageWrapper>
          <StyledImage
            fluid={imageThumbnail?.fluid || image?.fluid}
            alt={imageThumbnail?.title || image?.title}
          />
        </ImageWrapper>
        <TextWrapper>
          <Title text={jobFunction ? 'capitalize' : 'lowercase'}>{title}</Title>
          {productDescriptionShort && <Type>{productDescriptionShort}</Type>}
          {jobFunction && <Type>{jobFunction}</Type>}
          {expert && <Type>{expert.title}</Type>}
          {expert && <Type>{expert.jobFunction}</Type>}
          {shopifyProduct && (
            <PriceWrapper>
              <Price shopifyProduct={shopifyProduct} />
            </PriceWrapper>
          )}
          {label && (
            <LabelWrapper>
              <ArticleLabel color={label.colorCode} text={label.title} />
            </LabelWrapper>
          )}
        </TextWrapper>
      </Wrapper>
    </>
  )
}

export default ResultCard
