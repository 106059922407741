import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, desktopBreakpoint, mobileVW } from '../../styles'
import { getPathname } from '../../utils'
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

const Text = styled.h3``
const Link = styled.a``

const Container = styled.div`
  background-color: ${({ $backgroundColor }) => colors[$backgroundColor]};
  max-width: ${mobileVW(314)};
  margin: 50px auto;
  padding: 22px 25px;
  border-radius: 7px;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    max-width: 447px;
  }

  & ${Text} {
    color: ${({ $backgroundColor }) =>
      $backgroundColor === 'brownDarkest' ? 'white' : null};
    font-size: ${mobileVW(20)};
    line-height: 1;
    margin: 0 0 ${mobileVW(24)} 0;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 28px;
      margin: 0 0 12px 0;
    }
  }

  & ${Link} {
    background-color: white;
    font-size: ${mobileVW(15)};
    padding: ${mobileVW(7)} ${mobileVW(17)};
    border-radius: 5px;
    text-decoration: none;
    color: inherit;
    display: inline-block;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      padding: 7px 17px;
    }
  }
`

const normalizeBreakLines = text =>
  text.split('\\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [])

function ColorBanner(props) {
  const { backgroundColor, text, externalLink, internalLink, linkText } = props
  const { node_locale } = useLayoutCtx()

  return (
    <Container $backgroundColor={backgroundColor}>
      <Text>{normalizeBreakLines(text)}</Text>
      <Link
        href={
          externalLink ||
          (internalLink && getPathname(node_locale, internalLink)) ||
          'javascript:void(0)'
        }
        target='_blank'
        rel='noopener noreferrer'
      >
        {linkText}
      </Link>
    </Container>
  )
}

ColorBanner.defaultProps = {
  backgroundColor: 'softOrange',
  text: '',
  linkText: '',
  internalLink: '',
  externalLink: '',
}

ColorBanner.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.keys(colors)),
  text: PropTypes.string,
  linkText: PropTypes.string,
  internalLink: PropTypes.string,
  externalLink: PropTypes.string,
}

export default ColorBanner
