import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { replaceCheckout } from '../actions'
import replaceCheckoutLineItems from '../shopify/replaceCheckoutLineItems'

// Hooks
import useStoreCtx from './context/useStoreCtx'

const useReplaceLineItem = () => {
  const [state, setState] = useState({
    lineItem: null,
    loading: false,
    error: null,
  })

  const stores = useStoreCtx()
  const { storeSubdomain: activeStoreSubdomain, checkouts } = useSelector(
    ({ storeSubdomain, checkouts }) => ({
      storeSubdomain,
      checkouts,
    }),
  )
  const dispatch = useDispatch()

  const replaceLineItem = async (
    input,
    storeSubdomain = activeStoreSubdomain,
  ) => {
    if (input) {
      const checkout = checkouts[storeSubdomain]
      const { client } = stores[storeSubdomain]

      setState({ lineItem: null, loading: true, error: null })
      try {
        const {
          data: {
            checkoutLineItemsReplace: {
              checkout: { id, webUrl, lineItems, customAttributes },
            },
          },
        } = await replaceCheckoutLineItems(client, {
          checkoutId: checkout.id,
          lineItems: checkout.lineItems.edges.reduce(
            (
              lineItems,
              {
                node: {
                  variant: { id: variantId },
                  quantity,
                  customAttributes,
                },
              },
            ) =>
              variantId === input.variantId
                ? lineItems
                : [...lineItems, { variantId, quantity, customAttributes }],
            input.quantity === 0 ? [] : [input],
          ),
        })

        const lineItem = lineItems.edges.find(
          ({
            node: {
              variant: { id: variantId },
            },
          }) => variantId === input.variantId,
        )

        setState({
          lineItem: lineItem ? lineItem.node : null,
          loading: false,
          error: null,
        })

        dispatch(
          replaceCheckout({
            ...checkout,
            id,
            webUrl,
            lineItems,
            customAttributes,
          }),
        )
      } catch (error) {
        setState({
          lineItem: null,
          loading: false,
          error,
        })
      }
    } else {
      setState({
        lineItem: null,
        loading: false,
        error: new Error('The "input" is missing.'),
      })
    }
  }

  return [replaceLineItem, state]
}

export default useReplaceLineItem
