import { getLocalStorageCheckoutKey } from './utils'

export const SELECT_STORE = 'SELECT_STORE'
export const selectStore = storeSubdomain => (dispatch, getState) => {
  const storeSubdomains = Object.keys(getState().checkouts)
  if (storeSubdomains.find(subdomain => subdomain === storeSubdomain)) {
    dispatch({ type: SELECT_STORE, storeSubdomain })
  } else {
    throw new Error('The store is not found.')
  }
}

export const REPLACE_CHECKOUT = 'REPLACE_CHECKOUT'
export const replaceCheckout = checkout => (dispatch, getState) => {
  const storeSubdomains = Object.keys(getState().checkouts)

  if (
    storeSubdomains.find(
      storeSubdomain => storeSubdomain === checkout.storeSubdomain,
    )
  ) {
    localStorage.setItem(
      getLocalStorageCheckoutKey(checkout.storeSubdomain),
      checkout.id,
    )
    dispatch({
      type: REPLACE_CHECKOUT,
      checkout: {
        ...checkout,
        customAttributes: checkout.customAttributes.map(({ key, value }) => ({
          key,
          value,
        })),
      },
    })
  } else {
    throw new Error('The store is not found.')
  }
}

export const SET_CART_OPEN = 'SET_CART_OPEN'
export const setCartOpen = cartOpen => ({ type: SET_CART_OPEN, cartOpen })

export const SET_SEARCH_OPEN = 'SET_SEARCH_OPEN'
export const setSearchOpen = searchOpen => ({
  type: SET_SEARCH_OPEN,
  searchOpen,
})

export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
export const setDrawerOpen = drawerOpen => ({
  type: SET_DRAWER_OPEN,
  drawerOpen,
})

export const SET_AUTH_READY = 'SET_AUTH_READY'
export const setAuthReady = value => ({ type: SET_AUTH_READY, value })
