import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { replaceCheckout } from '../actions'
import queryCheckout from '../shopify/queryCheckout'
import createCheckout from '../shopify/createCheckout'

// Utils
import { getLocalStorageCheckoutKey } from '../utils'

// Hooks
import useStoreCtx from './context/useStoreCtx'

const hydrateCheckout = async (client, storeSubdomain, dispatch) => {
  let checkoutId = localStorage.getItem(
    getLocalStorageCheckoutKey(storeSubdomain),
  )
  let checkout

  const createValidCheckout = async client => {
    const {
      data: {
        checkoutCreate: { checkout: shopifyCheckout },
      },
    } = await createCheckout(client, { input: {} })
    return shopifyCheckout
  }

  const queryValidCheckout = async (id, client) => {
    const {
      data: { node },
    } = await queryCheckout(client, { id })
    return node
  }

  if (checkoutId) {
    let node
    try {
      node = await queryValidCheckout(checkoutId, client)
      if (node && node.orderStatusUrl) {
        checkout = await createValidCheckout(client)
      } else {
        checkout = node
      }
      if (
        !!node.lineItems.edges.length &&
        node.lineItems.edges.every(lineItem => {
          return !lineItem.node.variant
        })
      ) {
        checkout = await createValidCheckout(client)
      }
    } catch (error) {
      checkout = await createValidCheckout(client)
    }
  } else {
    checkout = await createValidCheckout(client)
  }

  // if (checkoutId) {
  //   const {
  //     data: { node },
  //   } = await queryCheckout(client, { id: checkoutId })

  //   if (node.orderStatusUrl) {
  //     const {
  //       data: {
  //         checkoutCreate: { checkout: shopifyCheckout },
  //       },
  //     } = await createCheckout(client, { input: {} })
  //     checkout = shopifyCheckout
  //   } else {
  //     checkout = node
  //   }
  // } else {
  //   const {
  //     data: {
  //       checkoutCreate: { checkout: shopifyCheckout },
  //     },
  //   } = await createCheckout(client, { input: {} })
  //   checkout = shopifyCheckout
  // }
  dispatch(replaceCheckout({ ...checkout, storeSubdomain }))
}

export const useCheckoutHydration = () => {
  const dispatch = useDispatch()
  const checkouts = useSelector(({ checkouts }) => checkouts)
  const stores = useStoreCtx()

  useEffect(() => {
    Object.values(checkouts).forEach(({ id, storeSubdomain }) => {
      if (!id) {
        hydrateCheckout(stores[storeSubdomain].client, storeSubdomain, dispatch)
      }
    })
  }, [checkouts, dispatch, stores])
}
