module.exports = {
  stores: [
    {
      // The subdomain of myshopify.com.
      subdomain: 'cycle-care',
      storefrontAccessToken: '85ce87b81437ec6e7772a9dc7c056f5d',
      apiVersion: '2023-04',
      // Define if this store should be used as the default store.
      //
      // If this is undefined or false in all stores, the first store will be

      // used as the default store.
      //
      // If this is true in more than one stores, the first store that sets this
      // to be true will be used as the default store.
      default: true,
      // Define properties that will be accessed later. E.g.,
      // currency: '$',
    },
  ],
  firebaseConfig: {
    apiKey: 'AIzaSyDUyD9mHdgE-FF3ezqAHUE6RZvikRvJrvU',
    authDomain: 'cycle-care.firebaseapp.com',
    databaseURL: 'https://cycle-care.firebaseio.com',
    projectId: 'cycle-care',
    storageBucket: 'cycle-care.appspot.com',
    messagingSenderId: '690740503955',
    appId: '1:690740503955:web:9244159f51c28005b8b466',
    measurementId: 'G-2NMZDCLF42',
  },
}
