import React, { useState, useRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import subscribe from 'klaviyo-subscribe'
import { mobileVW, colors, font, desktopBreakpoint } from '../../styles'

// Utils
import { validateEmail } from '../../utils'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'

// Components
import RichText from './RichText'
import Checkbox from './uiComponents/Checkbox'

// Styles
const errorFlash = keyframes`
  to {
    color: ${colors.red};
    border-color: ${colors.red};
  }
`

const Form = styled.form`
  width: ${mobileVW(320)};

  @media (min-width: ${desktopBreakpoint}) {
    width: 320px;
  }
`

const Field = styled.div`
  width: 100%;
  height: ${mobileVW(40)};
  position: relative;
  border-radius: 100px;
  background: ${({ background }) => background};
  border: solid ${mobileVW(2)} ${({ background }) => background};
  display: flex;
  align-items: center;

  &.error,
  &.error input {
    animation: ${errorFlash} 1s ease alternate-reverse;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: 40px;
    border-width: 2px;
  }
`

const Input = styled.input`
  height: auto;
  width: 100%;
  appearance: none;
  border: none;
  color: ${colors.brownDark};
  font-family: ${font.ben};
  font-weight: normal;
  font-size: ${mobileVW(16)};
  padding: 0 ${mobileVW(19)};
  display: flex;
  align-items: center;
  border-radius: inherit;
  background: none;

  &:focus {
    outline: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    padding: 0 19px;
  }
`

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: inherit;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-weight: normal;
  font-size: ${mobileVW(16)};
  height: 100%;
  padding: 0 ${mobileVW(25)};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border: solid ${mobileVW(2)} transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 25px;
    border-width: 2px;
    font-size: 16px;
  }
`

const Footer = styled.div`
  height: 0;
  overflow: visible;
  margin-top: ${mobileVW(26)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 33px;
  }
`

const GDPR = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${mobileVW(10)};

  &.error > * {
    animation: ${errorFlash} 1s ease alternate-reverse;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 10px;
  }
`

const GDPRText = styled.div`
  margin-left: ${mobileVW(14)};
  color: white;

  @media (min-width: ${desktopBreakpoint}) {
    margin-left: 14px;
  }

  p,
  b,
  i,
  em,
  strong {
    font-size: ${mobileVW(15)};
    font-family: ${font.larsseit};
    line-height: ${mobileVW(19)};
    color: inherit;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 15px;
      line-height: 19px;
    }
  }
`

const SuccessMessage = styled.p`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(15)};
  line-height: ${mobileVW(19)};
  text-align: center;
  color: ${colors.brownDark};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 15px;
    line-height: 19px;
  }
`

const NewsletterEmail = ({
  placeholder,
  buttonText,
  buttonColor,
  buttonBackground,
  gdpr,
  gdprText,
  backgroundColor,
  className,
  listId = 'J5hmrR',
}) => {
  const dictionary = useDictionaryCtx()

  const [email, setEmail] = useState()
  const [accepted, setAccepted] = useState(!gdpr)
  const [subscribed, setSubscribed] = useState(false)
  const fieldRef = useRef()
  const gdprRef = useRef()

  useEffect(() => {
    if (!email || !subscribed) return
    ;(async function() {
      try {
        await subscribe(listId, email)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [subscribed])

  const handleSubmit = e => {
    e.preventDefault()

    if (subscribed) return

    if (!validateEmail(email)) {
      if (!(fieldRef && fieldRef.current)) return

      fieldRef.current.classList.remove('error')

      setTimeout(() => fieldRef.current.classList.add('error'), 0)

      if (!accepted) {
        if (!(gdprRef && gdprRef.current)) return

        gdprRef.current.classList.remove('error')

        setTimeout(() => gdprRef.current.classList.add('error'), 0)
      }

      return
    }

    if (!accepted) {
      if (!(gdprRef && gdprRef.current)) return

      gdprRef.current.classList.remove('error')

      setTimeout(() => gdprRef.current.classList.add('error'), 0)

      return
    }

    setSubscribed(true)
  }

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <Field background={backgroundColor} ref={fieldRef}>
        {subscribed ? (
          <SuccessMessage>{dictionary.emailSubscribeMessage}</SuccessMessage>
        ) : (
          <>
            <Input
              type='email'
              placeholder={placeholder}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              type='submit'
              background={buttonBackground}
              color={buttonColor}
              border={backgroundColor}
            >
              {buttonText}
            </Button>
          </>
        )}
      </Field>

      <Footer>
        {!subscribed && (
          <GDPR ref={gdpr === true ? gdprRef : null}>
            {gdpr === true && (
              <Checkbox onChange={value => setAccepted(value)} />
            )}
            {gdprText && (
              <GDPRText>
                <RichText json={gdprText} />
              </GDPRText>
            )}
          </GDPR>
        )}
      </Footer>
    </Form>
  )
}

export default NewsletterEmail
