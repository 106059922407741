import React from 'react'

const ArrowFilled = props => (
  <svg
    id='Laag_1'
    dataname='Laag 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 216.23 159.36'
  >
    <defs></defs>
    <path
      className='cls-1'
      d='M258.88,339.05a23.65,23.65,0,0,1-37.73,0l-46.84-62L137.23,228c-11.77-15.58-.66-37.9,18.86-37.9H323.94c19.53,0,30.64,22.32,18.87,37.9L305.73,277Z'
      transform='translate(-131.9 -189.58)'
    />
  </svg>
)

export default ArrowFilled
