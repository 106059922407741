import React from 'react'

const ArrowRight = props => (
  <svg
    height='20.302'
    viewBox='0 0 41.334 20.302'
    width='41.334'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='m0 1.717h40.034m0 0a9.576 9.576 0 0 1 -9.649-9.5m0 19a9.576 9.576 0 0 1 9.649-9.5'
      fill='none'
      stroke='#ff7002'
      strokeLinecap='round'
      strokeWidth='1.3'
      transform='translate(.65 8.434)'
    />
  </svg>
)

export default ArrowRight
