import React from 'react'
import styled from 'styled-components'
import {
  colors,
  font,
  mobileVW,
  letterSpacing,
  desktopBreakpoint,
} from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'
import LogoOutlineSmall from '../graphics/LogoOutlineSmall'
import Facebook from '../graphics/Facebook'
import Instagram from '../graphics/Instagram'
import NewsletterBlock from '../shared/modules/newsletterBlock'

const StyledFooter = styled.footer`
  background: ${colors.brownDarkest};
  color: ${colors.beigeUltraLight};
  font-size: ${mobileVW(14)};
  letter-spacing: ${letterSpacing(6)};
  padding: ${mobileVW(45)} 0 0;
  display: ${props => (props.hidden ? 'none' : 'block')};

  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.hidden ? 'none' : 'grid')};
    grid-template-areas:
      'logo menus newsletter'
      '. menus newsletter'
      '. menus socials'
      'links links .';
    padding: 64px 56px 32px 57px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${mobileVW(95)} 0 ${mobileVW(32)};
  text-align: left;

  svg {
    width: ${mobileVW(70)};
    @media (min-width: ${desktopBreakpoint}) {
      width: 100px;
    }
  }

  .socials {
    display: block;
    @media (min-width: ${desktopBreakpoint}) {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    flex: 0 0 25%;
    padding: 0;
    text-align: left;
  }
`

// const Tagline = styled.div`
//   margin-top: ${mobileVW(10)};
//   font-size: ${mobileVW(17)};

//   @media (min-width: ${desktopBreakpoint}) {
//     margin-top: ${newDesktopVW(10)};
//     font-size: inherit;
//   }
// `

const Menu = styled.ul`
  break-inside: avoid-column;

  &:first-of-type {
    h4 {
      margin-top: 0;
    }
  }
`

const MenusWrapper = styled.ul`
  padding: ${mobileVW(80)} ${mobileVW(31)} 0;
  column-count: 2;
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 35px 0 107px;
    grid-area: menus;
  }
`
const MenuLink = styled.li`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;
  }
  &:not(:last-of-type) {
    margin-bottom: ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 8px;
    }
  }
  &:last-of-type {
    padding-bottom: ${mobileVW(24)};
    @media (min-width: ${desktopBreakpoint}) {
      padding-bottom: 0;
    ]
  }

`
const Heading = styled.h3`
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: ${mobileVW(20)};
  margin-bottom: ${mobileVW(24)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 28px;
    margin-bottom: 0;
  }
`

// const Mail = styled.a`
//   display: inline-block;
//   text-decoration: underline;

//   @media (min-width: ${desktopBreakpoint}) {
//     display: block;
//   }
// `

const SocialLink = styled.a`
  display: inline-block;
  margin: ${mobileVW(5)} ${mobileVW(10)} 0 0;

  &:not(:last-of-type) {
    margin-right: ${mobileVW(36)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 5px 10px 0 0;
  }

  svg {
    width: ${mobileVW(20)};

    @media (min-width: ${desktopBreakpoint}) {
      width: 20px;
    }
  }

  path {
    fill: ${colors.beigeUltraLight};
  }
`

// const Slogan = styled.div`
//   display: none;

//   @media (min-width: ${desktopBreakpoint}) {
//     display: block;
//     width: 100%;
//     margin: ${newDesktopVW(80)} 0 ${newDesktopVW(40)};
//     font-family: ${font.nib};
//     font-size: ${newDesktopVW(38)};
//     text-align: center;
//     color: ${colors.babyBlue};

//     b {
//       font-family: ${font.marby};
//       font-weight: 300;
//     }
//   }
// `

const MenuTitle = styled.h4`
  font-family: ${font.ben};
  font-size: ${mobileVW(20)};
  margin-bottom: ${mobileVW(24)};
  font-weight: normal;
  text-transform: lowercase;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 15px;
  }
`

const Legal = styled.div`
  background: ${colors.brownDarkest};
  color: ${colors.brownDark};
  font-size: ${mobileVW(9)};
  margin: ${mobileVW(40)} 0 0;
  letter-spacing: ${letterSpacing(37)};
  padding: ${mobileVW(15)} ${mobileVW(20)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    grid-area: links;
    margin: 35px 0 0;
    font-size: 14px;
    padding: 0;
    flex-direction: row;
  }
`

const LegalMessage = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
  }
`

const LegalMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${mobileVW(10)};
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    padding-top: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`
const LegalMenuLink = styled.li`
  margin-right: ${mobileVW(5)};
  display: inline-block;

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: 5px;
  }
`
const Logo = styled.div`
  grid-area: logo;
`

const Socials = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: socials;
    padding-bottom: 76px;
    max-width: 230px;
    a {
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        svg path {
          fill: ${colors.beigeDark};
        }
      }
    }
  }
`

const Newsletter = styled.div`
  .homepage_newsletter_wrapper {
    color: ${colors.orangeLight};
    display: block;
    background-color: inherit;
  }

  @media (min-width: ${desktopBreakpoint}) {
    grid-area: newsletter;
    max-width: 340px;
  }
`

const Footer = ({ hidden }) => {
  const {
    node_locale,
    footerLogoTagline,
    footerMenu,
    footerMenuV2,
    newsletterBlock,
    footerSocialHeading,
    footerSocialMailAddress,
    instagramLink,
    facebookLink,
    footerEmailCaptureHeading,
    footerEmailCapturePlaceholder,
    footerLegalMessage,
    footerLegalMenu,
    footerSlogan,
  } = useLayoutCtx()

  return (
    <StyledFooter hidden={hidden}>
      <Newsletter>
        <NewsletterBlock content={newsletterBlock} />
      </Newsletter>
      <LogoWrapper>
        <Logo>
          <LogoOutlineSmall />
        </Logo>
        {/* <Tagline>{footerLogoTagline}</Tagline> */}
        <Socials className='socials'>
          <Heading>{footerSocialHeading}</Heading>
          {/* <Mail target='_blank' href={`mailto:${footerSocialMailAddress}`}>
              {footerSocialMailAddress}
            </Mail> */}
          <SocialLink target='_blank' aria-label='Instagram' rel='noopener'>
            <Instagram />
          </SocialLink>
          <SocialLink
            target='_blank'
            href={facebookLink}
            aria-label='Facebook'
            rel='noopener'
          >
            <Facebook />
          </SocialLink>
        </Socials>
      </LogoWrapper>
      <MenusWrapper>
        {footerMenuV2.map((menu, i) => (
          <Menu key={i}>
            <MenuTitle>{menu.title}</MenuTitle>
            {menu.links.map(({ title, link }) => (
              <MenuLink key={title}>
                <Link to={getPathname(node_locale, link[0].slug)}>{title}</Link>
              </MenuLink>
            ))}
          </Menu>
        ))}
      </MenusWrapper>
      {/* <Slogan>
        <RichText json={footerSlogan.json} />
      </Slogan> */}
      <Legal>
        <LegalMessage>{footerLegalMessage}</LegalMessage>
        <LegalMenu>
          {footerLegalMenu.map(({ title, link }) => (
            <LegalMenuLink key={title}>
              <Link to={getPathname(node_locale, link[0].slug)}>{title}</Link>
              <span> •</span>
            </LegalMenuLink>
          ))}
        </LegalMenu>
      </Legal>
      <Socials>
        <Heading>{footerSocialHeading}</Heading>
        {/* <Mail target='_blank' href={`mailto:${footerSocialMailAddress}`}>
              {footerSocialMailAddress}
            </Mail> */}
        <SocialLink
          target='_blank'
          aria-label='Instagram'
          rel='noopener'
          href={instagramLink}
        >
          <Instagram />
        </SocialLink>
        <SocialLink
          target='_blank'
          href={facebookLink}
          aria-label='Facebook'
          rel='noopener'
        >
          <Facebook />
        </SocialLink>
      </Socials>
    </StyledFooter>
  )
}

export default Footer
