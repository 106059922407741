import { combineReducers } from 'redux'
import {
  SELECT_STORE,
  REPLACE_CHECKOUT,
  SET_CART_OPEN,
  SET_DRAWER_OPEN,
  SET_SEARCH_OPEN,
  SET_AUTH_READY,
  SET_LOGIN_REFERRER,
} from './actions'

export const storeSubdomain = (state = '', { type, storeSubdomain }) =>
  type === SELECT_STORE ? storeSubdomain : state

export const checkouts = (state = {}, { type, checkout }) =>
  type === REPLACE_CHECKOUT
    ? {
        ...state,
        [checkout.storeSubdomain]: checkout,
      }
    : state

export const cartOpen = (state = false, { type, cartOpen }) =>
  type === SET_CART_OPEN ? cartOpen : state

export const drawerOpen = (state = false, { type, drawerOpen }) =>
  type === SET_DRAWER_OPEN ? drawerOpen : state

export const searchOpen = (state = false, { type, searchOpen }) =>
  type === SET_SEARCH_OPEN ? searchOpen : state

const authReady = (state = false, { type, value }) =>
  type === SET_AUTH_READY ? value : state

const root = combineReducers({
  storeSubdomain,
  checkouts,
  cartOpen,
  searchOpen,
  authReady,
  drawerOpen,
})

export default root
